/*
	Модальные окна.
	Общие стили несущего окна.

	В макете для вывода модального окна скрипт добавляет в <body>
	класс «modal__open-…» (окончание определяет тематику окна).
	Для закрытия — класс удаляется.

	Бокс модальных окон (#modal__box) расположен в конце HTML.
	Содержит общий блок вывода фона и отдельные тематические блоки.

	Эффект (открытие):
		из центра разворачивается окно;
		проявляетются элементы формы;
		под модальным окном затеняющий фон и размытое основное содержание.
*/

/*========================/
  Бокс для модальных окон
/========================*/

/*--- Окно скрыто ---*/

/*
	Все содержимое бокса находится под слоем основного содержания.
	Возврат на задний план происходит после задержки (1.5s),
	чтобы анимация сворачивания окна проходила над основным содержанем.
*/
#modal__box {
  z-index: -10;
  transition-property: z-index;
  transition-delay: 1.5s;
}

/*--- Окно выведено ---*/

/*
	Все содержимое бокса выводим над слоем основного содержания.
	Переход на передний план происходит сразу (0.1s),
	чтобы анимация разворачивания окна проходила над основным содержанем.
*/
[class*="modal__open-"] #modal__box {
  z-index: 100;
  transition-delay: 0.1s;
}

/*==================/
  Фон заднего плана
/==================*/

/*--- Окно скрыто ---*/

/* Позиционируем, задаём цвет фона. */
#modal__box .modal__back {
  position: fixed;
  inset: 0;
  background: var(--gray-47);

  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.9s;
  transition-timing-function: linear;
  transition-delay: 0.5s;
}

/*--- Окно выведено ---*/

[class*="modal__open-"] #modal__box .modal__back {
  opacity: 0.7;
  transition-delay: 0.1s;
}

/*=======================/
  Тематический контейнер
/=======================*/

/*----------------/
  Общие параметры
/----------------*/

/*--- Окно скрыто ---*/

/* Позиционируем и задаём размер для скрытого окна. */
#modal__box .modal__window {
  position: fixed;
  inset: 30% 0 0 50%;
  opacity: 0;
  z-index: -10;

  width: calc(var(--window-width) / 10);
  height: calc(var(--window-height) / 10);

  box-shadow: var(--shadow-modal);

  transition-property: all;
  transition-duration: 0.7s;
  transition-timing-function: linear;
  transition-delay: 0.4s;
}

/* Параметры анимации для открытия. */
/* .modal__window.creating__building,
.modal__window.creating__estimate,
.modal__window.adding__estimate-type,
.modal__window.creating__foreman,
.modal__window.locking__foreman,
.modal__window.unlocking__foreman,
.modal__window.adding__bank,
.modal__window.locking__bank,
.modal__window.unlocking__bank,
.modal__window.creating__chain {
	transition-property: all;
	transition-duration: 0.7s;
	transition-timing-function: linear;
	transition-delay: 0.4s;
} */

/*--- Окно выведено ---*/

/* Сопоставляем темы страницы и открываемого окна. */
.modal__open--creating-building #modal__box .modal__window.creating__building,
.modal__open--creating-estimate #modal__box .modal__window.creating__estimate,
.modal__open--creating-foreman #modal__box .modal__window.creating__foreman,
.modal__open--locking-foreman #modal__box .modal__window.locking__foreman,
.modal__open--unlocking-foreman #modal__box .modal__window.unlocking__foreman,
.modal__open--adding-bank #modal__box .modal__window.adding__bank,
.modal__open--locking-bank #modal__box .modal__window.locking__bank,
.modal__open--unlocking-bank #modal__box .modal__window.unlocking__bank,
.modal__open--creating-chain #modal__box .modal__window.creating__chain,
#modal__box.modal__open--adding-estimate-type
  .modal__window.adding__estimate-type {
  top: 45%;
  opacity: 1;
  z-index: 101;

  margin-top: calc(
    var(--window-height) / -2
  ); /* Отрицательные 50% от высоты окна (height). */
  margin-left: calc(
    var(--window-width) / -2
  ); /* Отрицательные 50% от ширины окна (width). */
  width: var(--window-width);
  height: var(--window-height);
}

/*--- Параметры анимации для закрытия ---*/

.modal__open--creating-building #modal__box .modal__window.creating__building,
.modal__open--creating-estimate #modal__box .modal__window.creating__estimate,
.modal__open--creating-foreman #modal__box .modal__window.creating__foreman,
.modal__open--locking-foreman #modal__box .modal__window.locking__foreman,
.modal__open--unlocking-foreman #modal__box .modal__window.unlocking__foreman,
.modal__open--adding-bank #modal__box .modal__window.adding__bank,
.modal__open--locking-bank #modal__box .modal__window.locking__bank,
.modal__open--unlocking-bank #modal__box .modal__window.unlocking__bank,
.modal__open--creating-chain #modal__box .modal__window.creating__chain {
  transition-delay: 0.1s;
}

#modal__box.modal__open--adding-estimate-type
  .modal__window.adding__estimate-type {
  transition-duration: 0.7s;
  transition-delay: 0.1s;
}

/*=======================/
  Основные элементы окна
/=======================*/

/*-------------/
  Все элементы
/-------------*/

/*--- Окно скрыто ---*/

#modal__box .modal__window * {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: linear;
  transition-delay: 0.1s;
}

#modal__box .modal__window:not(.adding__estimate-type) * {
  align-self: center;
}

/*--- Окно выведено ---*/

.modal__open--creating-building #modal__box .modal__window.creating__building *,
.modal__open--creating-estimate #modal__box .modal__window.creating__estimate *,
.modal__open--creating-foreman #modal__box .modal__window.creating__foreman *,
.modal__open--locking-foreman #modal__box .modal__window.locking__foreman *,
.modal__open--unlocking-foreman #modal__box .modal__window.unlocking__foreman *,
.modal__open--adding-bank #modal__box .modal__window.adding__bank *,
.modal__open--locking-bank #modal__box .modal__window.locking__bank *,
.modal__open--unlocking-bank #modal__box .modal__window.unlocking__bank *,
.modal__open--creating-chain #modal__box .modal__window.creating__chain *,
#modal__box.modal__open--adding-estimate-type
  .modal__window.adding__estimate-type
  * {
  opacity: 1;
  transition-delay: 0.8s;
}

/*----------/
  Заголовок
/----------*/

#modal__box .modal__window h3 {
  background: var(--back-nav);
  padding-top: 6px;
  padding-bottom: 7px;
  text-align: center;
  color: var(--back);
  text-shadow: var(--shadow-contour);
}

/*---------/
  Инфоблок
/---------*/

#modal__box .modal__window div {
  margin-bottom: 16px;
  border-bottom: solid 1px var(--text-head);
  background: hsl(var(--blue), 28%, 91%);
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}

.inputDate {
  background: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.inputDate div {
  background: none !important;
  border: none !important;
  margin: 0 !important;
  padding-bottom: 3px !important;
  padding-top: 2px !important;
}

.inputDate .rdtPicker {
  background-color: white !important;
}