.container {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--text-head);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  align-items: center;
  display: flex;
  height: 39px;
  justify-content: space-between;
}

.status {
  display: flex;
  color: white;
  font-size: 14px;
  border: solid 1px var(--back);
  width: 170px;
  justify-content: center;
  padding: 2px 5px 3px;
}

.firstButtons {
  width: 325px;
  display: flex;
  justify-content: center;
}

.date {
  font-family: 'thin';
  color: rgb(234, 232, 230);
  font-size: 14px;
}

.linkObject {
  color: var(--link-normal-light);
  border-bottom: 0.5px dotted white;
  font-size: 12px;
  color: var(--link-normal-light);
}

.containerDate {
  display: flex;
  justify-content: space-between;
  width: 560px;
}