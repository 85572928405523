.orderBox {
  display: grid;
  grid-template-columns: 616px auto;
  gap: 16px;
  margin-bottom: 8px;
}

.previewSelect {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  min-height: 46px;
  margin-bottom: 8px;

  &Images {
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    gap: 4px;

    img {
      height: 46px;
      border: solid 2px var(--gray-67);
      cursor: pointer;
      user-select: none;
      transition: all 0.15s;

      &:hover {
        border-color: var(--button-back-hover);
      }

      &.selected {
        border-color: var(--button-back-normal);
      }
    }
  }

  &Prev, &Next {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-height: 100%;
    cursor: pointer;
    user-select: none;
    transition: all 0.15s;

    &::before {
      transition: all 0.15s;
    }

    &:hover {
      border-color: var(--button-back-hover);
    }

    &:active {
      border-color: var(--button-back-active);
    }
  }

  &Prev {
    border-right: 1px solid var(--button-back-normal);

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 20px 10px 0;
      border-color: transparent var(--button-back-normal) transparent transparent;
    }

    &:hover::before {
      border-color: transparent var(--button-back-hover) transparent transparent;
    }

    &:active::before {
      border-color: transparent var(--button-back-active) transparent transparent;
    }
  }

  &Next {
    border-left: 1px solid var(--button-back-normal);

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 20px;
      border-color: transparent transparent transparent var(--button-back-normal);
    }

    &:hover::before {
      border-color: transparent transparent transparent var(--button-back-hover);
    }

    &:active::before {
      border-color: transparent transparent transparent var(--button-back-active);
    }
  }
}

.preview {
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    cursor: zoom-in;
  }
}

.section {
  margin-bottom: 6px;

  &Header {
    display: grid;
    grid-template-columns: 28px auto 60px 22px;
    grid-template-areas: "area-1 area-2 area-3 area-4";
    align-items: center;
    border: solid 1px var(--gray-67);
    background: var(--back__head-section);
    padding: 3px 6px;
    color: var(--back__tab-hover);

    & > *:nth-child(1) { grid-area: area-1; }   /* Переключатель. */
    & > *:nth-child(2) { grid-area: area-2; }   /* Название Секции. */
    & > *:nth-child(3),
    &.complaints > *:nth-child(3) { grid-area: area-3; }   /* «Файлов:» / «Всего:» */
    & > *:nth-child(4) { grid-area: area-4; }   /* Количество. */

    /* «Файлов:» / «Всего:» / Количество. */
    & > *:nth-child(3),
    &.complaints > *:nth-child(3),
    & > *:nth-child(4),
    &.complaints > *:nth-child(4) {
      justify-self: end;
    }

    &:global(.open),
    &.complaints:global(.open) {
      border-bottom: none;
    }

    /* Название Секции. */
    & > *:nth-child(2),
    &.complaints > *:nth-child(2) {
      font-size: 16px;
    }

    /* Надпись «Файлов:» / «Всего:» и количество. */
    & > *:nth-child(3),
    & > *:nth-child(4),
    &.complaints > *:nth-child(3),
    &.complaints > *:nth-child(4) {
      font-size: 12px;
    }

    /* Надпись «Файлов:» / «Всего:» */
    & > *:nth-child(3),
    &.complaints > *:nth-child(3) {
      font-family: 'thin';
    }
  }

  &Content {
    border: solid 1px var(--gray-67);
    background: var(--back-main);
    padding-top: 6px;
    padding-bottom: 4px;
  }
  
  &Files {
    & > div > *:nth-child(1) { grid-area: area-1; justify-self: start; }   /* Маркер. */
    & > div > *:nth-child(2) { grid-area: area-2; }   /* Ссылка. */
    & > div > *:nth-child(3) { grid-area: area-3; }

    & > div {
      display: grid;
      grid-template-columns: 16px auto 320px;
      grid-template-areas: "area-1 area-2 area-3";
      align-items: center;
      margin-left: 24px ;
      margin-bottom: 4px;
    }

    /* Ссылка на файл. */
    & > div > *:nth-child(2) {
      line-height: 16px;
    }
    & > div a {
      border-bottom: dotted 1px;
    }

    /* Комментарий. */
    & > div > *:nth-child(3) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  
  &Complaints {
    & > .captions {
      display: grid;
      grid-template-columns: 18px 168px 196px 120px auto 12px;
      column-gap: 6px;
      /* grid-template-rows: auto; */
      grid-template-areas:
	". area-1 area-2 area-3 area-4 .";
      align-items: center;
    }

    & > .captions > *:nth-child(1) { grid-area: area-1; }   /* Даты. */
    & > .captions > *:nth-child(2) { grid-area: area-2; }   /* Статус. */
    & > .captions > *:nth-child(3) { grid-area: area-3; }   /* Ответчик. */
    & > .captions > *:nth-child(4) { grid-area: area-4; }   /* Простой. */

    & > .captions {
      margin-bottom: 6px;
      border-bottom: solid 1px var(--gray-77);
      padding-bottom: 6px;
      font-family: 'thin';
    }

    /* Простой. */
    & > .captions > :nth-child(4) {
      justify-self: end;
    }

    /*--- Секция споров: Строка Спора ---*/

    & > .complaintRow {
      display: grid;
      grid-template-columns: 18px 122px 40px auto 110px 40px 24px 12px;
      column-gap: 6px;
      /* grid-template-rows: auto; */
      grid-template-areas:
	". area-1 . area-2 area-3 area-4 area-5 .";
      align-items: center;
    }

    & > .complaintRow > *:nth-child(1) { grid-area: area-1; }   /* Даты. */
    & > .complaintRow > *:nth-child(2) { grid-area: area-2; }   /* Статус. */
    & > .complaintRow > *:nth-child(3) { grid-area: area-3; }   /* Ответчик. */
    & > .complaintRow > *:nth-child(4) { grid-area: area-4; }   /* Дни. */
    & > .complaintRow > *:nth-child(5) { grid-area: area-5; }   /* «дн.» */

    /* Даты. */
    & > .complaintRow > a {
      display: grid;
      grid-template-columns: 54px auto 54px;
      /* grid-template-rows: auto; */
      grid-template-areas:
	"area-1 area-2 area-3";
      align-items: center;
    }

    & > .complaintRow > a > *:nth-child(1) { grid-area: area-1; }   /* Начало. */
    & > .complaintRow > a > *:nth-child(2) { grid-area: area-2; }   /* Тире. */
    & > .complaintRow > a > *:nth-child(3) { grid-area: area-3; }   /* Окончание. */

    /* Даты: ссылка. */
    & > .complaintRow > a {
      border-bottom: dotted 1px;
    }

    /* Окончание. */
    & > .complaintRow > a > *:nth-child(3) {
      justify-self: end;
    }

    /* Дни. */
    & > .complaintRow > *:nth-child(4) {
      justify-self: end;
    }

    /* «дн.» */
    & > .complaintRow > *:nth-child(5) {
      justify-self: end;
      font-family: 'thin';
    }

    /*--- Секция споров: Итоговая строка ---*/

    & > .totalRow {
      display: grid;
      grid-template-columns: 18px 220px auto 42px 24px 12px;
      column-gap: 6px;
      grid-template-rows: 22px;
      grid-template-areas:
	". area-1 area-2 area-3 area-4 .";
      align-items: center;
    }

    & > .totalRow > *:nth-child(1) { grid-area: area-1; }   /* Кнопка. */
    & > .totalRow > *:nth-child(2) { grid-area: area-2; }   /* «Всего…» */
    & > .totalRow > *:nth-child(3) { grid-area: area-3; }   /* Дни. */
    & > .totalRow > *:nth-child(4) { grid-area: area-4; }   /* «дн.» */

    /* Панель. */
    & > .totalRow {
      margin-top: 8px;
      margin-bottom: 2px;
      border-top: solid 1px var(--gray-77);
      padding-top: 2px;
    }

    /* Кнопка. */
    & > .totalRow > *:nth-child(1) {
      align-self: end;
    }

    /* «Всего…» */
    & > .totalRow > *:nth-child(2) {
      justify-self: end;
      font-family: 'thin';
    }

    /* Дни. */
    & > .totalRow > *:nth-child(3) {
      justify-self: end;
    }

    /* «дн.» */
    & > .totalRow > *:nth-child(4) {
      justify-self: end;
      font-family: 'thin';
    }
  }
}

.prices {
  margin-top: 16px;
  background: var(--back-main);
  padding-bottom: 2px;
  border: solid 1px var(--gray-67);
  border-top: none;

  &Header {
    margin-right: -1px;
    margin-left: -1px;
    background: var(--back__tab-hover);
    padding-top: 3px;
    padding-bottom: 4px;
    text-align: center;
    color: var(--back);
    font-size: 16px;
  }

  &Row {
    display: grid;
    grid-template-columns: 28px auto 60px 60px 60px 68px 46px;
    grid-template-areas: "area-1 area-2 area-3 area-4 area-5 area-6 area-7";
    align-items: center;
    margin: 0 6px;
    border-top: solid 1px var(--gray-67);
    padding-top: 2px;
    padding-bottom: 2px;
    font-family: 'thin';
    font-size: 13px;

    &:first-child {
      border-top: none;
    }

    & > *:nth-child(1) { grid-area: area-1; }   /* Переключатель. */
    & > *:nth-child(2) { grid-area: area-2; }   /* Название. */
    & > *:nth-child(3) { grid-area: area-3; justify-self: end; }   /* Количество. */
    & > *:nth-child(4) { grid-area: area-4; }   /* Единица измерения. */
    & > *:nth-child(5) { grid-area: area-5; justify-self: end; }   /* Цена. */
    & > *:nth-child(6) { grid-area: area-6; justify-self: end; }   /* Стоимость. */
    & > *:nth-child(7) { grid-area: area-7; justify-self: end; }   /* Трудоёмкость. */

    & > *:nth-child(1) {
      height: 32px;
    }
    & > *:nth-child(1) span {
      margin-top: 6px;
    }

    /* Название. */
    & > *:nth-child(2) {
      font-family: 'regular';
      line-height: 14px;
    }

    /* Количество. */
    & > *:nth-child(3) {
      padding-right: 6px;
    }

    & > *:nth-child(7) {
      display: flex;
      flex-direction: row;
    }

    /* Буквы «ч/д» и «дн.» */
    & > *:nth-child(7) > :nth-child(2) {
      padding-left: 4px;
    }
  }

  &Structure {
    margin-bottom: 12px;
    font-size: 12px;

    & .position {
      display: grid;
      grid-template-columns: 16px auto 60px 60px;
      /* grid-template-rows: auto; */
      grid-template-areas:
		"area-1 area-2 area-3 area-4";
      align-items: center;
    }

    & .position > *:nth-child(1) { grid-area: area-1; justify-self: start; }   /* Маркер. */
    & .position > *:nth-child(2) { grid-area: area-2; }   /* Название. */
    & .position > *:nth-child(3) { grid-area: area-3; justify-self: end; }   /* Количество. */
    & .position > *:nth-child(4) { grid-area: area-4; }   /* Единица измерения. */

    & .position {
      margin-left: 24px ;
    }

    /* Название. */
    & .position > *:nth-child(2) {
      line-height: 14px;
    }

    /* Количество. */
    & .position > *:nth-child(3) {
      padding-right: 6px;
    }
  }
}