.main {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
}

.labelBlockColor {
  color: white;
}

.labelBlockActiveColor {
  color: var(--text-head);
}

.inputForm {
  margin: 10px 0 10px 10px;
  width: auto;
}

label {
  width: auto;
}

.whiteBackground {
  display: flex;
  flex-direction: column;
  background: var(--back-main);
  width: 1239px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1239px;
  flex-wrap: wrap;
}

fieldset {
  padding-left: 150px;
  padding-right: 150px;
  display: flex;
  justify-content: space-between;
}

.tabs {
  padding-left: 150px;
  padding-right: 150px;
  margin-top: 12px;
}

.tabsButtons {
  margin: 0 10px 10px 0;
}

.positionSpan {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.labelBlock {
  background: var(--text-head);
  padding: 10px 90px;
  width: 310px;
  height: 40px;
  font-family: "bold";
  cursor: pointer;
}

.labelBlockActive {
  background: var(--back-main);
  color: var(--text-head);
  padding: 10px 90px;
  width: 310px;
  height: 40px;
  cursor: pointer;
}

.labelBlock:hover {
  opacity: .8;
  transition-duration: .2s;
}

.legend {
  width: 100%;
  padding-left: 6px;
  background: var(--back__head-section);
  color: var(--text__head-section)
}

.form {
  width: 1239px;
  padding-top: 12px;
}

.fieldset {
  margin-bottom: 16px;
}

.allField {
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
  width: 100%;
}

.label {
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 120px;
  margin-bottom: 20px;
}