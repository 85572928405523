.label {

}

.label:hover {
  cursor: pointer;
}

.label:after {
  content: 'Тип сметы';
  border-bottom: 1px dotted blue;
}