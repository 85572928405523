.container {
  display: flex;
  justify-content: space-around;
  width: 610px;
  margin-left: 14px;
  color: rgb(16, 62, 81);
  font-size: 12px;
  padding: 4px 0;
}

.name {
  width: 314px;
  padding-left: 6px;
}

.container:hover {
  background: rgb(27, 107, 141);
  opacity: .8;
  color: white;
}