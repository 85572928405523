.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 76px;
  background: var(--back-nav);
  //grid-area: header;
  //display: grid;
  //grid-template-columns: auto 970px 10px 260px auto;
  //grid-template-rows: 76px;
  //grid-template-areas: ". child-1 child-2 child-3 .";
  display: flex;
  padding-right: 30px;
  justify-content: space-between;
  align-items: center;

  nav {
    //display: grid;
    //grid-template-columns: auto 970px 10px 260px auto;
    //grid-template-rows: 76px;
    //grid-template-areas: ". child-1 child-2 child-3 .";
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    align-items: center;
    margin-left: 20px;
  }

  .top-bar__user--box {
    display: grid;
    grid-template-columns: 260px;
    grid-template-rows: 19px 27px 18px;
    grid-template-areas:
        "child-1"
        "child-2"
        "child-3";
    align-items: center;
    justify-self: end;
    justify-items: end;
    padding-right: 35px;
  }

  a.nav {
    position: relative;
    width: 108px;
    height: 36px;
    background: var(--text-head);
    padding-top: 8px;
    padding-left: 8px;
    transition: all .3s linear;
  }

  .tabs {
    margin-left: 20px !important;
  }
}