.container {
  height: 600px;
  background: rgb(234, 232, 230);
  margin: -10px 350px 20px -300px;
}

.form {
  display: flex;
  flex-direction: column;
}

.firstFieldSet {
  justify-content: space-around;
  width: 100%;
  padding: 0;
}

.secondFieldSet {
  justify-content: space-between;
  width: 100%;
  padding: 0;
}

.threeFieldSet {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}

.foreFieldSet {
  display: flex;
  width: 100%;
  padding: 0;
  height: 56px;
}

.nonSelect {
  background: #eadedc;
  color: #be5747 !important;
  font-family: 'bold';
  font-size: 11px;
}

.selected {
  font-family: 'bold';
  color: #3f8955 !important;
  font-size: 11px;
}