.orderRow {
  display: grid;
  grid-template-columns: 0 200px 200px auto 120px 150px 130px 70px 90px 0;
  grid-column-gap: 6px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		". area-1 area-3 area-4 area-5 area-7 area-9 area-10 area-11 ."
		". area-2 area-3 area-4 area-6 area-8 area-9 area-10 area-11 .";
  align-items: center;
  margin-bottom: 6px;
  border: solid 1px var(--gray-81);
  background: var(--back-main);
  padding: 4px 0;

  & > *:nth-child(1)  { grid-area: area-1;  }   /* Объект. */
  & > *:nth-child(2)  { grid-area: area-2;  }   /* Адрес. */
  & > *:nth-child(3)  { grid-area: area-3;  }   /* Смета. */
  & > *:nth-child(4)  { grid-area: area-4;  }   /* Наряд. */
  & > *:nth-child(5)  { grid-area: area-5;  }   /* Сроки. */
  & > *:nth-child(6)  { grid-area: area-6;  }   /* Стоимость. */
  & > *:nth-child(7)  { grid-area: area-7;  }   /* Бригадир. */
  & > *:nth-child(8)  { grid-area: area-8;  }   /* Продолжительность. */
  & > *:nth-child(9)  { grid-area: area-9;  }   /* Статус. */
  & > *:nth-child(10) { grid-area: area-10; }   /* Споры. */
  & > *:nth-child(11) { grid-area: area-11; }   /* Ответчики/Время. */

  /*--- Адрес ---*/

  & > *:nth-child(2) {
    font-family: 'thin';
    font-size: 12px;
  }

  /*--- Сроки ---*/

  & > *:nth-child(5) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 10px;
  }

  /* Тире. */
  & > *:nth-child(5) > *:nth-child(2) {
    padding-right: 4px;
    padding-left: 4px;
  }

  /*--- Стоимость ---*/

  & > *:nth-child(6) {
    justify-self: center;
  }

  /* Символ рубля. */
  & > *:nth-child(6)::after {
    padding-left: 4px;
    font-family: 'thin';
    font-size: 13px;
  }

  /*--- Бригадир ---*/

  & > *:nth-child(7) {
    padding-right: 0;   /* Убираем отступ класса «limit__text--length». */
    text-align: center;
    font-size: 12px;
  }

  /*--- Продолжительность ---*/

  & > *:nth-child(8) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  /* Буквы «дн.» */
  & > *:nth-child(8) > *:nth-child(2) {
    padding-left: 6px;
    font-family: 'thin';
  }

  /*--- Статус ---*/

  /* Блок (статус/кнопки). */
  & > *:nth-child(9) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.error &Status {
    color: var(--error-dark);
  }

  &.warning &Status {
    color: var(--orange-site);
  }

  /* Текст во псевдоэлементе по центру. */
  .page__order--list [class*="status__order--"] .text__status--order {
    text-align: center;
    line-height: 14px;
  }

  /* Раскраска статусов.*/
  .page__order--list .status__order--overdue-launch .text__status--order::before,
  .page__order--list .status__order--overdue-brigade .text__status--order::before,
  .page__order--list .status__order--overdue-done .text__status--order::before {
    color: var(--error-dark);
  }
  .page__order--list .status__order--overdue-launch:hover .text__status--order::before,
  .page__order--list .status__order--overdue-brigade:hover .text__status--order::before,
  .page__order--list .status__order--overdue-done:hover .text__status--order::before {
    color: var(--error-light);
  }

  /*--- Кнопки в строке (под Статусом) ---*/

  /* Кнопки изначально не выводятся. */
  & button, & a {
    display: flex;   /* flex — исходное значение. */
    width: 122px;
    border: solid 1px var(--gray-87);   /* Граница на фоне выделяемой строки. */
    margin-top: 4px;
  }

  /* Вывод кнопок в контексте роли и статуса. */
  .page__order--list.role__foreman .status__order--accept [name="control__order--launch"],
  .page__order--list.role__foreman .status__order--overdue-launch [name="control__order--launch"],

  .page__order--list.role__contractor .status__order--offer [name="control__order--offer"],
  .page__order--list.role__foreman .status__order--prepaid [name="control__order--work"],

  .page__order--list.role__foreman .status__order--work [name="control__complaint--initiate"],
  .page__order--list.role__foreman .status__order--complaint [name="control__complaint--end"],

  .page__order--list.role__foreman .status__order--work-done [name="control__order--work-repair"],
  .page__order--list.role__foreman .status__order--work-done [name="control__order--work-accept"] {
    display: flex;   /* flex — исходное значение. */
    width: 122px;
    border: solid 1px var(--gray-87);   /* Граница на фоне выделяемой строки. */
    margin-top: 4px;
  }

  .page__order--list button[name="control__order--work-accept"] {
    margin-top: 2px;
  }

  /*--- Споры ---*/

  & > *:nth-child(10) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  /* Слэш. */
  & > *:nth-child(10) > *:nth-child(2) {
    padding-right: 4px;
    padding-left: 4px;
  }

  /*--- Ответчики ---*/

  & > *:nth-child(11) {
    display: grid;
    grid-template-columns: 34px 14px 32px;
    /* grid-template-rows: auto; */
    grid-template-areas:
		"area-1 area-2 area-3"
		"area-4 area-5 area-6";
    justify-self: center;
    justify-items: center;
  }

  & > *:nth-child(11) > *:nth-child(1) { grid-area: area-1; }   /* «П» */
  & > *:nth-child(11) > *:nth-child(2) { grid-area: area-2; }   /* «/» */
  & > *:nth-child(11) > *:nth-child(3) { grid-area: area-3; }   /* «Б» */
  & > *:nth-child(11) > *:nth-child(4) { grid-area: area-4; }   /* Число. */
  & > *:nth-child(11) > *:nth-child(5) { grid-area: area-5; }   /* «/» */
  & > *:nth-child(11) > *:nth-child(6) { grid-area: area-6; }   /* Число. */

  & > *:nth-child(11) {
    font-size: 12px;
  }

  /* Дни. */
  & > *:nth-child(11) > *:nth-child(4),
  & > *:nth-child(11) > *:nth-child(6) {
    height: 18px;
    background: hsl(var(--orange-basic), 87%);
    padding: 0 4px;
    color: var(--error-dark);
  }

  &:hover > *:nth-child(11) > *:nth-child(4),
  &:hover > *:nth-child(11) > *:nth-child(6) {
    text-shadow: none;
  }
}