.container {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.notAccept {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  background: var(--gray-77);
  border-color: var(--gray-67);
  color: var(--gray-57);
  cursor: not-allowed;
}

.activeContainer {
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.openMenu {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  border: solid 1px var(--gray-67);
  background: var(--back-main);
  padding-top: 6px;
  padding-bottom: 4px;
}

.textarea {
  margin-top: 2px;
  resize: none;
  border: solid 1px var(--gray-77);
  padding: 2px 4px;
  width: 474px;
  height: 36px;
  font-size: 12px;
  line-height: 14px;
}

.file {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
}

.span {
  background: var(--gray-67) !important;
  border: 1px solid var(--gray-67) !important;
  cursor: not-allowed !important;
}

.size {
  width: 610px;
}