.statusBar {
  display: grid;
  grid-template-columns: 330px 150px 140px 110px 150px auto 84px;
  column-gap: 8px;
  grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5 area-6 area-7";
  align-items: center;
  justify-items: center;
  top: 123px;
  margin-bottom: 8px;
  min-height: 44px;
  background: var(--text-head);
  position: sticky;
  z-index: 1;
  width: 1240px;
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);

  & > *:nth-child(1) { grid-area: area-1; }   /* Название Наряда. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Даты. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Трудоёмкость. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Стоимость. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Статус. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Бригада. */
  & > *:nth-child(7) { grid-area: area-7; }   /* Простой. */

  & > *:nth-child(1) {
    justify-self: start;
    padding: 2px 6px 3px;
    line-height: 16px;
  }

  & > *:nth-child(2) > :nth-child(2) {
    padding-right: 4px;
    padding-left: 4px;
  }

  & > *:nth-child(3) {
    display: grid;
    grid-template-columns: 42px 8px 46px;
    grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 .       area-3";
  }

  & > *:nth-child(3) > :nth-child(1) { grid-area: area-1; }   /* «Продолжительность». */
  & > *:nth-child(3) > :nth-child(2) { grid-area: area-2; }   /* Дни. */
  & > *:nth-child(3) > :nth-child(3) { grid-area: area-3; }   /* «дн.» */

  & > *:nth-child(3) > :nth-child(1) {
    align-self: end;
    justify-self: center;
    margin-bottom: -2px;
    font-family: 'thin';
    font-size: 12px;
  }

  & > *:nth-child(3) > :nth-child(2) {
    align-self: start;
    justify-self: end;
  }

  & > *:nth-child(3) > :nth-child(3) {
    align-self: start;
    justify-self: start;
  }

  & > *:nth-child(4) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > *:nth-child(4) > :nth-child(2) {
    font-family: 'thin';
    font-size: 12px;
  }

  & > *:nth-child(5) span {
    display: inline-block;
    border: solid 1px var(--back);
    padding: 2px 5px 3px;
    text-align: center;
    line-height: 14px;
  }

  & > *:nth-child(6) a {
    display: inline-block;
    margin-bottom: 4px;
    border-bottom: dotted 1px;
  }

  & > *:nth-child(7) {
    display: grid;
    grid-template-columns: 28px 6px 28px;
    grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 .       area-3";
  }

  & > *:nth-child(7) > :nth-child(1) { grid-area: area-1; }   /* «Простой». */
  & > *:nth-child(7) > :nth-child(2) { grid-area: area-2; }   /* Дни. */
  & > *:nth-child(7) > :nth-child(3) { grid-area: area-3; }   /* «дн.» */

  & > *:nth-child(7) > :nth-child(1) {
    align-self: end;
    justify-self: center;
    font-family: 'thin';
    font-size: 12px;
  }

  & > *:nth-child(7) > :nth-child(2) {
    align-self: start;
    justify-self: end;
    height: 18px;
    background: hsl(var(--orange-basic), 87%);
    padding: 0 4px;
    font-family: 'bold';
    color: var(--error-dark);
  }

  & > *:nth-child(7) > :nth-child(3) {
    align-self: start;
    justify-self: start;
    font-family: 'thin';
  }
}