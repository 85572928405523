.container {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.activeContainer {
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.openMenu {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  border: solid 1px var(--gray-67);
  background: var(--back-main);
  padding-top: 6px;
  padding-bottom: 4px;
}

.file {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
}

.size {
  width: 610px;
}