.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.blocks {
  display: flex;
  justify-content: space-between;
}

.blockName {
  font-family: "thin";
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--text-head);
}

.barRequest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 1;
  width: 1240px;
  height: 50px;
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);
  margin-bottom: 8px;
  background: var(--back__tab-hover);
  padding-left: 6px;
}

.barRequest:nth-child(1) {
  padding-bottom: 1px;
}

.barRequest:nth-child(2) {
  font-size: 12px;
}

.linkObject {
  color: var(--link-normal-light);
  border-bottom: 0.5px dotted white;
  font-size: 14px;
}

.addressObject {
  font-family: 'thin';
}

.nameEstimate {
  font-size: 14px;
}

.typeEstimate {
  font-family: 'thin';
}

.note {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--back);
  font-weight: 300;
  padding: 3px 4px;
  font-family: 'thin';
  width: 300px;
  height: 38px;
  border: 1px solid gray;
  line-height: 14px;
  overflow: auto;
}

.barRequest:nth-child(4) {
  font-size: 12px;
}

.PlannedDates {
  margin-right: 26px;
}

.blockNameSecond {
  font-family: thin-italic;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-head);
}