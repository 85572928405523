.container {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.activeContainer {
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.notAccept {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  background: var(--gray-77);
  border-color: var(--gray-67);
  color: var(--gray-57);
  cursor: not-allowed;
}

.openMenu {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  border: solid 1px var(--gray-67);
  background: var(--back-main);
  padding-top: 6px;
  padding-bottom: 4px;
}

.form {
  margin: 12px 8px 4px;
  border-top: solid 1px var(--gray-77);
  padding-top: 12px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.span {
  margin-top: 2px;
  margin-right: 8px;
  justify-self: end;
  color: rgb(16, 62, 81);
  font-size: 12px;
  font-weight: bold;
}

.textarea {
  width: 480px;
  resize: none;
  height: 34px;
  border: 1px solid rgb(196, 196, 196);
}

.noAccessSpan {
  background: var(--gray-67) !important;
  border: 1px solid var(--gray-67) !important;
  cursor: not-allowed !important;
}

.file {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
}

.size {
  width: 610px;
}