.datePicker {
  position: relative;

  input[type="text"] {
    width: 100%;
    padding-right: 20px;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 4px;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    fill: rgba(#000, 0.5);
  }

  :global(.react-datepicker-popper) {
    z-index: 10;
  }
}