.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.blockName {
  font-family: "thin";
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--text-head);
}

.blockNameSecond {
  font-family: thin-italic;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-head);
}

.barRequest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 1;
  width: 1240px;
  height: 50px;
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);
  margin-bottom: 8px;
  background: var(--back__tab-hover);
  padding-left: 6px;
}

.linkObject {
  color: var(--link-normal-light);
  border-bottom: 0.5px dotted white;
  font-size: 14px;
}

.addressObject {
  font-family: 'thin';
  font-size: 12px;
}

.date {
  color: rgb(234, 232, 230);
  font-size: 14px;
  font-weight: bold;
  padding: 0 4px;
}

.barRequest:last-child {
  padding-right: 18px;
}