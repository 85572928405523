.changeData {
  width: 480px;

  &Notification {
    width: 100%;
    border-bottom: solid 1px var(--gray-83);
    background: var(--blue48_89);
    padding-top: 12px;
    padding-bottom: 6px;
    text-align: center;
  }
  
  &Content {
    padding: 16px 12px;
    display: grid;
    grid-template-columns: 152px auto;

    grid-template-rows:
		auto
		/* repeat(4, 32px) */
		repeat(4, 28px)

		auto
		/* repeat(13, 32px) */
		repeat(13, 28px)

		auto
		/* repeat(6, 32px) */
		repeat(6, 28px)

		repeat(2, auto);

    grid-template-areas:
		"area-1  area-1 "
		"area-2  area-3 "
		"area-4  area-5 "
		"area-6  area-7 "
		"area-8  area-9 "
		"area-10 area-10"
		"area-11 area-11"
		"area-12 area-12"

		"area-13 area-14"
		"area-15 area-16"
		"area-17 area-18"
		"area-19 area-20"
		"area-21 area-22"

		"area-23 area-23"
		"area-24 area-24"
		"area-25 area-25"
		"area-26 area-26"
		"area-27 area-28"
		"area-29 area-30"

		"area-31 area-31"

		"area-32 area-33"
		"area-34 area-35"
		"area-36 area-37"
		"area-38 area-39"

		"area-40 area-40"
		"area-41 area-41"

		"area-42 area-42"
		"area-43 area-44";
    align-items: center;

    & > *:nth-child(1)  { grid-area: area-1;  }   /* «Ответственный…» */

    & > *:nth-child(2)  { grid-area: area-2;  }   /* «Фамилия». */
    & > *:nth-child(3)  { grid-area: area-3;  }   /* Фамилия. */
    & > *:nth-child(4)  { grid-area: area-4;  }   /* «Имя». */
    & > *:nth-child(5)  { grid-area: area-5;  }   /* Имя. */
    & > *:nth-child(6)  { grid-area: area-6;  }   /* «Отчество». */
    & > *:nth-child(7)  { grid-area: area-7;  }   /* Отчество. */
    & > *:nth-child(8)  { grid-area: area-8;  }   /* «E-mail». */
    & > *:nth-child(9)  { grid-area: area-9;  }   /* E-mail. */

    & > *:nth-child(10) { grid-area: area-10; }   /* «Реквизиты…» */

    & > *:nth-child(11) { grid-area: area-11; }   /* «Наименование». */
    & > *:nth-child(12) { grid-area: area-12; }   /* Наименование. */

    & > *:nth-child(13) { grid-area: area-13; }   /* «ИНН». */
    & > *:nth-child(14) { grid-area: area-14; }   /* ИНН. */
    & > *:nth-child(15) { grid-area: area-15; }   /* «КПП». */
    & > *:nth-child(16) { grid-area: area-16; }   /* КПП. */
    & > *:nth-child(17) { grid-area: area-17; }   /* «ОКПО». */
    & > *:nth-child(18) { grid-area: area-18; }   /* ОКПО. */
    & > *:nth-child(19) { grid-area: area-19; }   /* «ОГРН». */
    & > *:nth-child(20) { grid-area: area-20; }   /* ОГРН. */
    & > *:nth-child(21) { grid-area: area-21; }   /* «ОКТМО». */
    & > *:nth-child(22) { grid-area: area-22; }   /* ОКТМО. */

    & > *:nth-child(23) { grid-area: area-23; }   /* «Адрес (юр…» */
    & > *:nth-child(24) { grid-area: area-24; }   /* Адрес (юр… */
    & > *:nth-child(25) { grid-area: area-25; }   /* «Адрес (ф…» */
    & > *:nth-child(26) { grid-area: area-26; }   /* Адрес (ф… */

    & > *:nth-child(27) { grid-area: area-27; }   /* «Совпадает с…» */
    & > *:nth-child(28) { grid-area: area-28; }   /* Флаг. */

    & > *:nth-child(29) { grid-area: area-29; }   /* «Телефон…» */
    & > *:nth-child(30) { grid-area: area-30; }   /* Телефон. */

    & > *:nth-child(31) { grid-area: area-31; }   /* «Подписант». */

    & > *:nth-child(32) { grid-area: area-32; }   /* «Фамилия». */
    & > *:nth-child(33) { grid-area: area-33; }   /* Фамилия. */
    & > *:nth-child(34) { grid-area: area-34; }   /* «Имя». */
    & > *:nth-child(35) { grid-area: area-35; }   /* Имя. */
    & > *:nth-child(36) { grid-area: area-36; }   /* «Отчество». */
    & > *:nth-child(37) { grid-area: area-37; }   /* Отчество. */

    & > *:nth-child(38) { grid-area: area-38; }   /* «Должность». */
    & > *:nth-child(39) { grid-area: area-39; }   /* Должность. */

    & > *:nth-child(40) { grid-area: area-40; }   /* «Основание». */
    & > *:nth-child(41) { grid-area: area-41; }   /* Основание. */

    & > *:nth-child(42) { grid-area: area-42; }   /* Линия. */

    & > *:nth-child(43) { grid-area: area-43; }   /* Кнопка регистрации. */
    & > *:nth-child(44),
    & > *:nth-child(45) { grid-area: area-44; }   /* Кнопки. */

    /* «Совпадает с…» */
    & > *:nth-child(27) {
      height: 28px;
      font-family: 'thin';
      font-size: 11px;
    }

    /* Флаг «Совпадает с…» */
    & > *:nth-child(28) {
      height: auto;
    }

    /* Поля по ширине окна. */
    & input {
      width: auto;
      height: 22px;

      &.error {
        border: 1px solid red;
      }
    }

    /* Кнопка «Сохранить». */
    & > *:nth-child(44) {
      margin-left: 16px;
    }
    & > *:nth-child(45) {
      justify-self: end;
    }

    /* Ширина кнопок. */
    & [name="close__save"],
    & [name="close__cancel"] {
      width: 136px;
    }

    & [name="close__apply"] {
      width: 152px;
    }
  }
}

.sectionTitle {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  background: var(--gray-87);
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  font-family: 'thin';
  font-size: 16px;
  text-shadow: var(--button-text-disable-shade);
}