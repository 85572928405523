.accountData {
  display: grid;
  grid-template-columns: 12px 350px auto 390px auto 390px 12px;
  grid-template-areas:
        ". area-1 . area-2 . area-3 ."
        ". area-4 area-4 area-4 area-4 area-4 .";

  .col1 {
    grid-area: area-1;
  }

  .col2 {
    grid-area: area-2;
    border-left: solid 1px var(--back-nav);
    padding-left: 12px;
  }

  .col3 {
    grid-area: area-3;
    border-left: solid 1px var(--back-nav);
    padding-left: 12px;
  }

  .bottom {
    grid-area: area-4;
    border-top: solid 1px var(--back-nav);
    padding: 12px 0;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 210px 320px auto;
    grid-template-areas: "area-1 area-2 .";

    button {
      width: 172px;
    }
  }

  .colSection {
    &:not(:first-child) {
      margin-top: 12px;
    }

    h6 {
      margin-bottom: 6px;
      font-size: 15px;
    }
  }

  .colSectionItem {
    display: grid;
    grid-template-columns: 120px auto;
    gap: 12px;
    margin-bottom: 8px;

    &.noMargin {
      margin-bottom: 0;
    }

    & > *:first-child {
      font-family: 'thin';
    }
  }
}

.check {
  &::before {
    content: "\221a";
  }
}

.noValue {
  font-family: 'thin-italic';
  color: var(--error-dark);
}