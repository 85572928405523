.buildingBox {
  &:not(.open) &InfoArea {
    display: grid;
    grid-template-columns: 16px 224px repeat(6, auto);
    grid-template-areas:
		"area-1 area-2 area-4 area-5 area-6 area-7 area-8 area-9"
		".       area-3 area-4 area-5 area-6 area-7 area-8 area-9";
    align-items: center;
  }

  &:not(.open) &InfoArea > *:nth-child(1) { grid-area: area-1; }   /* Переключатель. */
  &:not(.open) &InfoArea > *:nth-child(2) { grid-area: area-2; }   /* Название. */
  &:not(.open) &InfoArea > *:nth-child(3) { grid-area: area-3; }   /* Адрес. */
  &:not(.open) &InfoArea > *:nth-child(4) { grid-area: area-4; }   /* Даты. */
  &:not(.open) &InfoArea > *:nth-child(5) { grid-area: area-5; }   /* Сумма: Всего. */
  &:not(.open) &InfoArea > *:nth-child(6) { grid-area: area-6; }   /* Сумма: Остаток. */
  &:not(.open) &InfoArea > *:nth-child(7) { grid-area: area-7; }   /* Сумма: В работе. */
  &:not(.open) &InfoArea > *:nth-child(8) { grid-area: area-8; }   /* Сумма: Выполнено. */
  &:not(.open) &InfoArea > *:nth-child(9) { grid-area: area-9; }   /* Сметы. */

  /*--- Даты ---*/

  &:not(.open) &InfoArea > *:nth-child(4) {
    display: grid;
    grid-template-columns: auto 30px auto;
    grid-template-areas:
		"area-1 area-2 area-3";
    align-items: center;
  }

  &:not(.open) &InfoArea > *:nth-child(4) > *:nth-child(1) { grid-area: area-1; justify-self: end;    }   /* Дата. */
  &:not(.open) &InfoArea > *:nth-child(4) > *:nth-child(2) { grid-area: area-2; justify-self: center; }   /* Тире. */
  &:not(.open) &InfoArea > *:nth-child(4) > *:nth-child(3) { grid-area: area-3; justify-self: start;  }   /* Дата. */

  /*--- Суммы ---*/

  &:not(.open) &InfoArea > *:nth-child(n+5):not(:nth-child(n+9)) {
    display: grid;
    /* grid-template-columns: max-content min-content auto; */
    grid-template-areas:
		"area-1"
		"area-2";
    align-items: center;
    justify-items: center;
  }

  &:not(.open) &InfoArea > *:nth-child(n+5):not(:nth-child(n+9)) > *:nth-child(1) { grid-area: area-1; }   /* Надпись. */
  &:not(.open) &InfoArea > *:nth-child(n+5):not(:nth-child(n+9)) > *:nth-child(2) { grid-area: area-2; }   /* Сумма. */

  /*--- Смет ---*/

  &:not(.open) &InfoArea > *:nth-child(9) {
    display: grid;
    grid-template-columns: auto 30px auto;
    grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 area-3 area-4";
    align-items: center;
    justify-items: center;
  }

  &:not(.open) &InfoArea > *:nth-child(9) > *:nth-child(1) { grid-area: area-1; }   /* Надпись. */
  &:not(.open) &InfoArea > *:nth-child(9) > *:nth-child(2) { grid-area: area-2; justify-self: end;   }   /* Число. */
  &:not(.open) &InfoArea > *:nth-child(9) > *:nth-child(3) { grid-area: area-3; }   /* Черта. */
  &:not(.open) &InfoArea > *:nth-child(9) > *:nth-child(4) { grid-area: area-4; justify-self: start; }   /* Число. */

  &.open {
    display: grid;
    grid-template-columns: 240px 12px auto;
    grid-template-rows: auto;
    grid-template-areas:
		"area-1 . area-2";
    align-items: start;
    justify-content: start;
  }

  &.open > *:nth-child(1) { grid-area: area-1; }   /* Данные Объекта. */
  &.open > *:nth-child(2) { grid-area: area-2; }   /* Список Смет. */

  /*--- Данные Объекта ---*/

  &.open &InfoArea {
    display: grid;
    //grid-template-columns: 16px 222px;
    align-items: center;
    grid-area: area-1;
  }

  &.open &InfoArea > *:nth-child(1)  { grid-area: area-1;  }   /* Переключатель. */
  &.open &InfoArea > *:nth-child(2)  { grid-area: area-2;  }   /* Название. */
  &.open &InfoArea > *:nth-child(3)  { grid-area: area-3;  }   /* Адрес. */
  &.open &InfoArea > *:nth-child(4)  { grid-area: area-4;  }   /* Даты. */
  &.open &InfoArea > *:nth-child(5)  { grid-area: area-5;  }   /* Сумма: Всего. */
  &.open &InfoArea > *:nth-child(6)  { grid-area: area-6;  }   /* Сумма: Остаток. */
  &.open &InfoArea > *:nth-child(7)  { grid-area: area-7;  }   /* Сумма: В работе. */
  &.open &InfoArea > *:nth-child(8)  { grid-area: area-8;  }   /* Сумма: Выполнено. */
  &.open &InfoArea > *:nth-child(9)  { grid-area: area-9;  }   /* Сметы. */

  &.open &InfoArea > *:nth-child(4) {
    //display: grid;
    //grid-template-columns: 90px 44px 70px;
    //grid-template-areas:
	//	"area-1 area-2 area-3";
    //align-items: center;
    //justify-items: end;
    display: block;
  }

  &.open &InfoArea > *:nth-child(4) > *:nth-child(1) { grid-area: area-1; }   /* Дата. */
  &.open &InfoArea > *:nth-child(4) > *:nth-child(2) { grid-area: area-2; justify-self: center; }   /* Тире. */
  &.open &InfoArea > *:nth-child(4) > *:nth-child(3) { grid-area: area-3; }   /* Дата. */

  /*--- Суммы ---*/

  &.open &InfoArea > *:nth-child(n+5):not(:nth-child(n+9)) {
    display: grid;
    grid-template-columns: 90px 114px;
    grid-template-areas:
		"area-1 area-2";
    align-items: center;
    justify-items: end;
  }

  &.open &InfoArea > *:nth-child(n+5):not(:nth-child(n+9)) > *:nth-child(1) { grid-area: area-1; }   /* Надпись. */
  &.open &InfoArea > *:nth-child(n+5):not(:nth-child(n+9)) > *:nth-child(2) { grid-area: area-2; }   /* Сумма. */

  /*--- Смет ---*/

  &.open &InfoArea > *:nth-child(9) {
    display: grid;
    grid-template-columns: 90px 69px 20px 25px;
    grid-template-areas:
		"area-1 area-2 area-3 area-4";
    align-items: center;
    justify-items: end;
  }

  &.open &InfoArea > *:nth-child(9) > *:nth-child(1) { grid-area: area-1; }   /* Надпись. */
  &.open &InfoArea > *:nth-child(9) > *:nth-child(2) { grid-area: area-2; }   /* Число. */
  &.open &InfoArea > *:nth-child(9) > *:nth-child(3) { grid-area: area-3; }   /* Черта. */
  &.open &InfoArea > *:nth-child(9) > *:nth-child(4) { grid-area: area-4; }   /* Число. */


  & {
    margin-bottom: 8px;
    border: solid 1px var(--gray-67);
    border-left-width: 8px;
    background: var(--back-building-jobplan);
    padding-top: 8px;
    padding-bottom: 8px;
  }

  /* Объект без Разбивок. */
  &.noJobPlans {
    background: var(--back-building-close);
  }

  /* Строка Объекта развёрнута. */
  &.open {
    border-left-color: var(--back-nav);
    background: var(--back-building-open);
    padding-bottom: 0;
  }


  /* Позиционируем относительно строки Объекта и строки Сметы. */
  &,
  &.open .estimateRow {
    position: relative;
  }

  /* Позиционируем индикатор в строке Объекта. */
  &:not(.open).alarm::before {
    top: 18px;
    left: -10px;
    position: absolute;
    width: 12px;
    height: 18px;
    background: var(--red-site);
    text-align: center;
    font-family: "bold";
    font-size: 12px;
    color: var(--back);
    content: "!";
  }

  /* Строка Объекта свёрнута — не выводим. */
  & button:global(.bttn__small) {
    display: none;
  }

  /* Строка Объекта развёрнута. */
  &.open button:global(.bttn__small) {
    display: flex;   /* flex — возвращаем исходное значение. */
    width: 92px;
  }

  /*-----------------------/
    Область данных Объекта
  /-----------------------*/

  /*--- Строка свёрнута ---*/

  /* Ячейки, начиная с 5-й: Убираем двоеточие. */
  &:not(.open) &InfoArea :global(.symbol__colon--after)::after {
    display: none;
  }

  /*--- Строка развёрнута ---*/

  /* Название Объекта и Адрес (выводим весь текст). */
  &.open &InfoArea > *:global(.limit__text--length) {
    white-space: normal;
    padding-right: 0;
  }

  /* Адрес (расстояние между строк). */
  &.open &InfoArea > *:nth-child(3) {
    margin-top: 8px;
    line-height: 14px;
  }

  &InfoArea > *:nth-child(3) {
    font-family: 'thin';
    font-size: 12px;
  }

  &InfoArea span:global(.circle-triangle) {
    top: -14px;
    left: -13px;
  }

  &InfoArea > *:nth-child(2) {
    padding-bottom: 1px;
  }

  &InfoArea > *:nth-child(2) a {
    border-bottom: dotted 1px;

    &:hover {
      border-bottom-style: solid;
    }
  }

  &.open &InfoArea {
    grid-template-rows: auto auto 40px 16px 16px 16px 16px 40px auto;
    grid-template-areas:
		"area-1 area-2 "
		".       area-3 "
		".       area-4 "
		".       area-5 "
		".       area-6 "
		".       area-7 "
		".       area-8 "
		".       area-9 "
		".       .       ";
  }
}

.estimatesArea {
  grid-area: area-2;
  display: block;
}

.estimateRow {
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;

  &.alarm::before {
    top: 0;
    left: -12px;
    position: absolute;
    width: 12px;
    height: 18px;
    background: var(--red-site);
    text-align: center;
    font-family: "bold";
    font-size: 12px;
    color: var(--back);
    content: "!";
  }

  & > *:nth-child(n) {
    padding-left: 6px;
    padding-right: 6px;
  }

  & {
    display: grid;
    grid-template-columns: 240px 210px repeat(4, 80px) 208px;
    /* grid-template-rows: *px; */
    grid-template-areas:
		"area-1 area-4 area-7 area-8 area-9 area-10 area-11"
		"area-2 area-5 area-7 area-8 area-9 area-10 area-11"
		"area-3 area-6 area-7 area-8 area-9 area-10 area-11";
    align-items: center;
  }

  & > *:nth-child(1)  { grid-area: area-1;  }   /* Название. */
  & > *:nth-child(2)  { grid-area: area-2;  }   /* Примечание. */
  & > *:nth-child(3)  { grid-area: area-3;  }   /* Прораб. */

  & > *:nth-child(4)  { grid-area: area-4;  }   /* Тип. */
  & > *:nth-child(5)  { grid-area: area-5;  }   /* Статус. */
  & > *:nth-child(6)  { grid-area: area-6;  }   /* Даты. */

  & > *:nth-child(7)  { grid-area: area-7;  }   /* Сумма: Всего. */
  & > *:nth-child(8)  { grid-area: area-8;  }   /* Сумма: Остаток. */
  & > *:nth-child(9)  { grid-area: area-9;  }   /* Сумма: В работе. */
  & > *:nth-child(10) { grid-area: area-10; }   /* Сумма: Выполнено. */

  & > *:nth-child(11) { grid-area: area-11; }   /* Информация. */

  /*--- Суммы ---*/

  & > *:nth-child(n+7):not(:nth-child(11)) {
    display: grid;
    /* grid-template-columns: max-content min-content auto; */
    grid-template-areas:
		"area-1"
		"area-2";
    justify-items: center;
  }

  & > *:nth-child(n+7):not(:nth-child(11)) > *:nth-child(1) { grid-area: area-1; align-self: end; }   /* Надпись. */
  & > *:nth-child(n+7):not(:nth-child(11)) > *:nth-child(2) { grid-area: area-2; align-self: start; }   /* Сумма. */


  & > *:nth-child(n):not(:nth-child(11)) {
    border-right: solid 1px var(--gray-91);
  }

  /* Граница во всю высоту строки Сметы. */
  & > *:nth-child(n+7):not(:nth-child(11)) {
    height: 100%;
  }

  &Status {
    font-family: 'bold';
  }

  & > *:nth-child(1) a {
    border-bottom: dotted 1px;
    color: var(--link-hover);
  }

  &:hover > *:nth-child(1) a {
    color: var(--link-normal-bright);
    text-shadow: none;
  }

  & > *:nth-child(1) a:hover {
    border-bottom-style: solid;
    color: var(--link-hover-light);
  }

  & > *:nth-child(2) {
    font-family: 'thin';
  }
}