.container {
  display: flex;
  justify-content: space-between;
  height: 47px;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  width: 100%;
  &__offer {
    background: var(--back-estimate-offer);
  }
  &__jobplan {
    background: var(--back-estimate-jobplan);
  }
  &__url {
    border-bottom: dotted 1px;
    padding-right: 0;
  }
}

.name {
  width: 240px;
  padding-left: 6px;
}

.sumContainer {
  width: 260px;
  display: flex;
  justify-content: space-around;
  padding-left: 6px;
}

.sum {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 71px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.types {
  width: 210px;
  padding-left: 6px;
}

.borderLine:nth-child(n):not(:last-child) {
  border-right: solid 1px var(--gray-87);
}

.container:hover {
  background: var(--button-back-normal);
  color: var(--button-text-normal);
  text-shadow: var(--button-text-normal-shade);
  cursor: pointer;
  transition: .5s;
}