.main {
  margin-left: 14px;
  margin-bottom: 4px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px var(--back-order-graph);
  background: hsl(138deg 12% 93%);
}

.info {
  font-size: 12px;
  font-family: 'thin';
  width: 140px;
}

.date {
  color: rgb(16, 62, 81);
  font-size: 12px;
  font-family: 'thin';
}

.item:hover {
  background: rgb(27, 107, 141);
  opacity: .8;
  color: white;
}