/*=====================================/
  Создание Сметы (.creating__estimate)
/=====================================*/

/*--- Размер окна контейнера ---*/

/*
	Основной размер окна определяем в переменных в контексте темы.
	Ширина окна --window-width должна учитывать:
	columns {сумма всех колонок} + margin-left 20px + поле справа 20px.
	Высота окна --window-height должна учитывать:
	общая высота строк вложенной формы + заголовок + отступ снизу.
*/
#modal__box .creating__estimate {
  --window-width: 580px;
  --window-height: 286px;
}

/* Фон окна. */
#modal__box .creating__estimate {
  background: var(--back-estimate-offer);
}

/*--- Области формы ---*/

#modal__box .creating__estimate form {
  display: grid;
  grid-template-columns: 140px 55px 155px 70px 120px;
  grid-template-rows: repeat(4, 32px) 64px;
  grid-template-areas:
    "child-1  child-2 child-2 child-2  child-2 "
    "child-3  child-4 child-4 child-4  child-4 "
    "child-5  child-6 child-6 child-6  child-6 "
    "child-7  child-8 child-9 child-10 child-11"
    "child-12 .       .       child-13 child-13";
  margin-left: 20px;
}

#modal__box .creating__estimate form > :nth-child(1) {
  grid-area: child-1;
} /* «Название». */
#modal__box .creating__estimate form > :nth-child(2) {
  grid-area: child-2;
} /* Название. */

#modal__box .creating__estimate form > :nth-child(3) {
  grid-area: child-3;
} /* «Примечание». */
#modal__box .creating__estimate form > :nth-child(4) {
  grid-area: child-4;
} /* Примечание. */

#modal__box .creating__estimate form > :nth-child(5) {
  grid-area: child-5;
} /* «Тип сметы» (кнопка-ссылка). */
#modal__box .creating__estimate form > :nth-child(6) {
  grid-area: child-6;
} /* Тип сметы. */

#modal__box .creating__estimate form > :nth-child(7) {
  grid-area: child-7;
} /* «Плановые сроки». */
#modal__box .creating__estimate form > :nth-child(8) {
  grid-area: child-8;
} /* «Старт». */
#modal__box .creating__estimate form > :nth-child(9) {
  grid-area: child-9;
} /* Дата. */
#modal__box .creating__estimate form > :nth-child(10) {
  grid-area: child-10;
} /* «Финиш». */
#modal__box .creating__estimate form > :nth-child(11) {
  grid-area: child-11;
} /* Дата. */

#modal__box .creating__estimate form > :nth-child(12) {
  grid-area: child-12;
} /* Кнопка. */
#modal__box .creating__estimate form > :nth-child(13) {
  grid-area: child-13;
  justify-self: end;
} /* Кнопка. */

#modal__box .creating__estimate form > :nth-child(2),
#modal__box .creating__estimate form > :nth-child(4),
#modal__box .creating__estimate form > :nth-child(6) {
  width: auto;
}

#modal__box .creating__estimate form > :nth-child(8),
#modal__box .creating__estimate form > :nth-child(10) {
  font-family: "thin";
}

#modal__box .creating__estimate form > :nth-child(9),
#modal__box .creating__estimate form > :nth-child(11) {
  width: 120px;
}

/*--- Кнопка-ссылка «Тип сметы» ---*/

/*
	Подпись к полю с названием типа сметы.
	Подпись выполнена в виде кнопки-ссылки.
	Общее оформление в формах «Создание Объекта» и «Создание Сметы».
*/

#modal__box .creating__estimate span.control__adding--estimate-type {
  border-bottom: dotted 1px;
  color: var(--link-normal);
  cursor: pointer;
}

#modal__box .creating__estimate span.control__adding--estimate-type:hover {
  border-bottom-style: solid;
  color: var(--link-hover);
}
