/*
	Страница «Список споров»
	.page__complaint--list
*/



/*================================================/
  *
/================================================*/

/*--------------------------/
  Панель заголовков колонок
/--------------------------*/

/* При прокрутке прижимается к верхней панели, но не прокручивается. */

/*--- Компоновка ---*/

.page__complaint--list .bar__captions {
	display: grid;
	grid-template-columns: 0 200px 200px 106px auto 150px 130px 90px 0;   
	grid-column-gap: 6px;
	grid-template-rows: 42px;
	grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7 .";
	align-items: center;
	justify-items: center;
}

.page__complaint--list .bar__captions > :nth-child(1) { grid-area: area-1; }   /* Объект/Адрес. */
.page__complaint--list .bar__captions > :nth-child(2) { grid-area: area-2; }   /* Смета/Наряд. */
.page__complaint--list .bar__captions > :nth-child(3) { grid-area: area-3; }   /* Тема. */
.page__complaint--list .bar__captions > :nth-child(4) { grid-area: area-4; }   /* Реплика. */
.page__complaint--list .bar__captions > :nth-child(5) { grid-area: area-5; }   /* Статус/Инициатор. */
.page__complaint--list .bar__captions > :nth-child(6) { grid-area: area-6; }   /* Спор/Простой. */
.page__complaint--list .bar__captions > :nth-child(7) { grid-area: area-7; }   /* Ответчик/Дни. */

/*--- Позиционирование ---*/

.page__complaint--list .bar__captions {
	position: sticky;
	z-index: 1;      /* Перекрываем прокручиваемое содержание. */
	top: 76px;

	width: 1240px;   /* Ширина контента. */
	margin-bottom: 6px;

	border-top: solid 1px var(--back);
	border-bottom: solid 1px var(--back);
	background: var(--back__tab-hover);
	text-align: center;
	font-family: 'thin';
	line-height: 16px;
	color: var(--back);
}

/*----------------/
  Панель фильтров
/----------------*/

/* При прокрутке прижимается к верхней панели, но не прокручивается. */

/*--- Компоновка ---*/

.page__complaint--list .bar__filters {
	display: grid;
	grid-template-columns: 0 200px 200px 106px auto 150px 130px 90px 0;   
	grid-column-gap: 6px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7.";
	align-items: center;
	justify-items: center;
}

.page__complaint--list .bar__filters > :nth-child(1) { grid-area: area-1; }   /* Объект/Адрес. */
.page__complaint--list .bar__filters > :nth-child(2) { grid-area: area-2; }   /* Смета/Наряд. */
.page__complaint--list .bar__filters > :nth-child(3) { grid-area: area-3; }   /* Тема. */
.page__complaint--list .bar__filters > :nth-child(4) { grid-area: area-4; }   /* Реплика. */
.page__complaint--list .bar__filters > :nth-child(5) { grid-area: area-5; }   /* Статус. */
.page__complaint--list .bar__filters > :nth-child(6) { grid-area: area-6; }   /* Даты (Спора). */
.page__complaint--list .bar__filters > :nth-child(7) { grid-area: area-7; }   /* Ответчик. */

/*--- Позиционирование ---*/

.page__complaint--list .bar__filters {
	position: sticky;
	z-index: 1;      /* Перекрываем прокручиваемое содержание. */
	top: 120px;

	width: 1240px;   /* Ширина контента. */
	margin-bottom: 6px;

	border-bottom: solid 1px var(--back);
	background: var(--back);
}

/*--- Элементы ---*/

.page__complaint--list .bar__filters > :nth-child(n) {
	width: 100%;
}

.page__complaint--list .bar__filters input,
.page__complaint--list .bar__filters select {
	height: 20px;
	font-size: 12px;
}

.page__complaint--list .bar__filters input {
	padding-bottom: 1px;
}

.page__complaint--list .bar__filters select {
	padding-right: 4px;
	text-align: center;
}

.page__complaint--list .bar__filters > :nth-child(6) {
	text-align: center;
}

/* Цвет пунктов в списке (Арбитраж/Простой по договору). */

.page__complaint--list .bar__filters [name="filter__status"] .important--item {
	color: var(--error-dark);
}

/*-------------/
  Строка Спора
/-------------*/

/*--- Компоновка ---*/

.page__complaint--list .row__complaint {
	display: grid;
	grid-template-columns: 0 200px 200px 102px auto 150px 130px 90px 0;
	grid-column-gap: 6px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 area-3 area-5 area-6 area-7 area-9  area-11 ."
		". area-2 area-4 area-5 area-6 area-8 area-10 area-12 .";
	align-items: center;
}

.page__complaint--list .row__complaint a {
	color: inherit;
}

.page__complaint--list .row__complaint > :nth-child(1)  { grid-area: area-1;  }   /* Объект. */
.page__complaint--list .row__complaint > :nth-child(2)  { grid-area: area-2;  }   /* Адрес. */

.page__complaint--list .row__complaint > :nth-child(3)  { grid-area: area-3;  }   /* Смета. */
.page__complaint--list .row__complaint > :nth-child(4)  { grid-area: area-4;  }   /* Наряд. */

.page__complaint--list .row__complaint > :nth-child(5)  { grid-area: area-5;  }   /* Тема. */

.page__complaint--list .row__complaint > :nth-child(6)  { grid-area: area-6;  }   /* Претензия. */

.page__complaint--list .row__complaint > :nth-child(7)  { grid-area: area-7;  }   /* Статус. */
.page__complaint--list .row__complaint > :nth-child(8)  { grid-area: area-8;  }   /* Инициатор. */

.page__complaint--list .row__complaint > :nth-child(9)  { grid-area: area-9;  }   /* Период Спора. */
.page__complaint--list .row__complaint > :nth-child(10) { grid-area: area-10; }   /* Период простоя. */

.page__complaint--list .row__complaint > :nth-child(11) { grid-area: area-11; }   /* Ответчик. */
.page__complaint--list .row__complaint > :nth-child(12) { grid-area: area-12; }   /* Время. */

.page__complaint--list .row__complaint > :nth-child(n+7) {
	justify-self: center;
}

/*--- Блок строки ---*/

.page__complaint--list .row__complaint {
	margin-bottom: 6px;
	border: solid 1px var(--gray-81);
	background: var(--back-main);
	padding: 4px 0;
}

/*--- Адрес (area-2) ---*/

.page__complaint--list .row__complaint > :nth-child(2) {
	font-family: 'thin';
	font-size: 12px;
}

/*--- Тема (area-5) ---*/

.page__complaint--list .row__complaint > :nth-child(5) {
	text-align: center;
	line-height: 16px;
}

/*--- Претензия (area-6) ---*/

.page__complaint--list .row__complaint > :nth-child(6) {
	height: 36px;
	overflow: hidden;
	font-family: 'thin';
	font-size: 11px;
	line-height: 12px;
}

/*--- Статус (area-7) ---*/

/*
	Текст названий статусов прописан во псевдоэлементах
	в общем файле «common_objects.css».
	Здесь — только оформление в контексте страницы.
*/

.page__complaint--list .row__complaint > :nth-child(7)::before {
	font-family: 'bold';
}

/* Цвет текста (успех): «Закрыт» / «Удовлетворён» (1 и 2). */
.page__complaint--list .row__complaint.status__complaint--end > :nth-child(7)::before,
.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(7)::before {
	color: var(--accept-dark);
}
.page__complaint--list .row__complaint.status__complaint--end:hover > :nth-child(7)::before,
.page__complaint--list .row__complaint.status__complaint--satisfied:hover > :nth-child(7)::before {
	color: var(--accept-light);
}

/* Цвет текста (внимание): «Арбитраж» / «Простой по договору». */
.page__complaint--list .row__complaint.status__complaint--arbitrate > :nth-child(7)::before,
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime > :nth-child(7)::before,
.page__complaint--list .row__complaint.status__complaint--downtime > :nth-child(7)::before {
	color: var(--error-dark);
}
.page__complaint--list .row__complaint.status__complaint--arbitrate:hover > :nth-child(7)::before,
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime:hover > :nth-child(7)::before,
.page__complaint--list .row__complaint.status__complaint--downtime:hover > :nth-child(7)::before {
	color: var(--error-light);
}

/*--- Инициатор (area-8) ---*/

.page__complaint--list .row__complaint > :nth-child(8) {
	padding-right: 0;      /* Убираем отступ класса «limit__text--length». */
	font-family: 'thin';
	font-size: 12px;
}

/*--- Период Спора (area-9) ---*/

.page__complaint--list .row__complaint > :nth-child(9) {
	display: grid;
	grid-template-columns: 50px 20px 50px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		"area-1 area-2 area-3";
}

.page__complaint--list .row__complaint > :nth-child(9) > :nth-child(1) { grid-area: area-1; }   /* Начало Спора. */
.page__complaint--list .row__complaint > :nth-child(9) > :nth-child(2) { grid-area: area-2; }   /* Тире. */
.page__complaint--list .row__complaint > :nth-child(9) > :nth-child(3),
.page__complaint--list .row__complaint > :nth-child(9) > :nth-child(4) { grid-area: area-3; }   /* Окончание Спора. */

/* Начало Спора. */
.page__complaint--list .row__complaint > :nth-child(9) > :nth-child(1) {
	justify-self: end;
}

/* Тире. */
.page__complaint--list .row__complaint > :nth-child(9) > :nth-child(2) {
	justify-self: center;
}

/*
	Окончание Спора:
		:nth-child(3) Троеточие не выводится в статусах: «Закрыт» / «Отклонён» / «Удовлетворён».
		:nth-child(4) Дата изначально не выводится.
*/
.page__complaint--list .row__complaint.status__complaint--end > :nth-child(9) > :nth-child(3),
.page__complaint--list .row__complaint.status__complaint--overrule > :nth-child(9) > :nth-child(3),
.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(9) > :nth-child(3),
.page__complaint--list .row__complaint > :nth-child(9) > :nth-child(4) {
	display: none;
}

/* Окончание Спора: Дата выводится в статусах: «Закрыт» / «Отклонён» / «Удовлетворён». */
.page__complaint--list .row__complaint.status__complaint--end > :nth-child(9) > :nth-child(4),
.page__complaint--list .row__complaint.status__complaint--overrule > :nth-child(9) > :nth-child(4),
.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(9) > :nth-child(4) {
	display: inline-block;
}

/*--- Период простоя (area-10) ---*/

.page__complaint--list .row__complaint > :nth-child(10) {
	display: grid;
	grid-template-columns: 50px 20px 50px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		"area-1 area-2 area-3";
}

.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(1) { grid-area: area-1; }   /* Начало простоя. */
.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(2) { grid-area: area-2; }   /* Тире. */
.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(3),
.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(4) { grid-area: area-3; }   /* Окончание простоя. */

/* Начало простоя: Дата изначально не выводится. */
.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(1) {
	display: none;
}

/* Начало простоя: Дата выводится начиная со статуса «Простой по договру». */
.page__complaint--list .row__complaint.status__complaint--downtime > :nth-child(10) > :nth-child(1),

.page__complaint--list .row__complaint.status__complaint--restart > :nth-child(10) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime > :nth-child(10) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--query-downtime > :nth-child(10) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--party-respond-downtime > :nth-child(10) > :nth-child(1),

.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(10) > :nth-child(1) {
	display: inline-block;
	justify-self: end;
}

/* Тире. */
.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(2) {
	justify-self: center;
}

/*
	Окончание простоя:
		:nth-child(3) Троеточие изначально не выводится.
		:nth-child(4) Дата изначально не выводится.
*/
.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(3),
.page__complaint--list .row__complaint > :nth-child(10) > :nth-child(4) {
	display: none;
}

/*
	Окончание простоя:
		:nth-child(3) Троеточие выводится начиная со статуса «Простой по договру».
		:nth-child(4) Дата выводится в статусе «Удовлетворён».
*/
.page__complaint--list .row__complaint.status__complaint--downtime > :nth-child(10) > :nth-child(3),

.page__complaint--list .row__complaint.status__complaint--restart > :nth-child(10) > :nth-child(3),
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime > :nth-child(10) > :nth-child(3),
.page__complaint--list .row__complaint.status__complaint--query-downtime > :nth-child(10) > :nth-child(3),
.page__complaint--list .row__complaint.status__complaint--party-respond-downtime > :nth-child(10) > :nth-child(3),

.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(10) > :nth-child(4) {
	display: inline-block;
}

/*--- Ответчик (area-11) ---*/

/*
	:nth-child(1) Тире не выводится начиная со статуса «Простой по договру».
	:nth-child(2) Ответчик (Подрядчик/Бригада) изначально не выводится.
*/
.page__complaint--list .row__complaint.status__complaint--downtime > :nth-child(11) > :nth-child(1),

.page__complaint--list .row__complaint.status__complaint--restart > :nth-child(11) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime > :nth-child(11) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--query-downtime > :nth-child(11) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--party-respond-downtime > :nth-child(11) > :nth-child(1),

.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(11) > :nth-child(1),
.page__complaint--list .row__complaint > :nth-child(11) > :nth-child(2) {
	display: none;
}

/* Ответчик (Подрядчик/Бригада) выводится начиная со статуса «Простой по договру». */
.page__complaint--list .row__complaint.status__complaint--downtime > :nth-child(11) > :nth-child(2),

.page__complaint--list .row__complaint.status__complaint--restart > :nth-child(11) > :nth-child(2),
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime > :nth-child(11) > :nth-child(2),
.page__complaint--list .row__complaint.status__complaint--query-downtime > :nth-child(11) > :nth-child(2),
.page__complaint--list .row__complaint.status__complaint--party-respond-downtime > :nth-child(11) > :nth-child(2),

.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(11) > :nth-child(2) {
	display: inline-block;
}

/*--- Дни (area-12) ---*/

/*
	:nth-child(1) Тире не выводится начиная со статуса «Простой по договру».
	:nth-child(2) Дни изначально не выводятся.
*/
.page__complaint--list .row__complaint.status__complaint--downtime > :nth-child(12) > :nth-child(1),

.page__complaint--list .row__complaint.status__complaint--restart > :nth-child(12) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime > :nth-child(12) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--query-downtime > :nth-child(12) > :nth-child(1),
.page__complaint--list .row__complaint.status__complaint--party-respond-downtime > :nth-child(12) > :nth-child(1),

.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(12) > :nth-child(1),
.page__complaint--list .row__complaint > :nth-child(12) > :nth-child(2) {
	display: none;
}

/* Дни выводятся начиная со статуса «Простой по договру». */
.page__complaint--list .row__complaint.status__complaint--downtime > :nth-child(12) > :nth-child(2),

.page__complaint--list .row__complaint.status__complaint--restart > :nth-child(12) > :nth-child(2),
.page__complaint--list .row__complaint.status__complaint--arbitrate-downtime > :nth-child(12) > :nth-child(2),
.page__complaint--list .row__complaint.status__complaint--query-downtime > :nth-child(12) > :nth-child(2),
.page__complaint--list .row__complaint.status__complaint--party-respond-downtime > :nth-child(12) > :nth-child(2),

.page__complaint--list .row__complaint.status__complaint--satisfied > :nth-child(12) > :nth-child(2) {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

/* Дни. */
.page__complaint--list .row__complaint > :nth-child(12) > :nth-child(2) > :nth-child(1) {
	height: 18px;
	background: hsl(var(--orange-basic), 87%);
	padding: 0 4px;
	font-family: 'bold';
	color: var(--error-dark);
}

/* «дн.» */
.page__complaint--list .row__complaint > :nth-child(12) > :nth-child(2) > :nth-child(2) {
	margin-left: 4px;
	font-family: 'thin';
}
