.main {
  border: 1px solid rgb(171, 171, 171);
  background: white;
  margin-bottom: 8px;
  padding: 2px 1px 0 2px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameContainer {
  display: flex;
}

.name {
  width: 390px;
  margin-left: 12px;
}

.graph {
  width: 566px;
}

.graphDate {
  display: flex;
  width: 200px;
  justify-content: center;
}

.date {
  color: rgb(16, 62, 81);
  font-size: 12px;
  font-family: 'thin';
}

.counter {
  padding-left: 76px;
}

.container:hover {
  background: rgb(27, 107, 141);
  opacity: .8;
  color: white;
}