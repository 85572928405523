/*=================================================/
  Страница Карточки спора (.page__complaint--card)
/=================================================*/

/*--------------------------/
  Родительские данные Спора
/--------------------------*/

/*--- Компоновка ---*/

/*--- Компоновка ---*/

.page__complaint--card .bar__parent-data {
	display: grid;
	grid-template-columns: repeat(3, 341px) auto;
	grid-column-gap: 8px;
	grid-template-rows: 24px 22px;
	grid-template-areas:
		"area-1 area-3 area-5 area-7"
		"area-2 area-4 area-6 area-7";
	/* align-items: center; */
}

.page__complaint--card .bar__parent-data > :nth-child(1) { grid-area: area-1; align-self: end;   }   /* Объект. */
.page__complaint--card .bar__parent-data > :nth-child(2) { grid-area: area-2; align-self: start; }   /* Адрес. */
.page__complaint--card .bar__parent-data > :nth-child(3) { grid-area: area-3; align-self: end;   }   /* Смета. */
.page__complaint--card .bar__parent-data > :nth-child(4) { grid-area: area-4; align-self: start; }   /* Наряд. */
.page__complaint--card .bar__parent-data > :nth-child(5) { grid-area: area-5; align-self: end;   }   /* Подрядчик: Организация. */
.page__complaint--card .bar__parent-data > :nth-child(6) { grid-area: area-6; align-self: start; }   /* Подрядчик: Данные. */
.page__complaint--card .bar__parent-data > :nth-child(7) { grid-area: area-7; }   /* Даты. */

/*--- Подрядчик: Данные ---*/

.page__complaint--card .bar__parent-data > :nth-child(6) {
	display: grid;
	grid-template-columns: 120px auto;
	grid-template-areas:
		"area-1 area-2";
	align-items: start;
}

.page__complaint--card .bar__parent-data > :nth-child(6) > :nth-child(1) { grid-area: area-1; }   /* Телефон. */
.page__complaint--card .bar__parent-data > :nth-child(6) > :nth-child(2) { grid-area: area-2; }   /* Имя Подрядчика. */

/*--- Даты. ---*/

.page__complaint--card .bar__parent-data > :nth-child(7) {
	display: grid;
	grid-template-columns: 78px auto 78px;
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 area-3 area-4";
}

.page__complaint--card .bar__parent-data > :nth-child(7) > :nth-child(1) { grid-area: area-1; align-self: end;   justify-self: center; }   /* Надпись. */
.page__complaint--card .bar__parent-data > :nth-child(7) > :nth-child(2) { grid-area: area-2; align-self: start; justify-self: end;    }   /* Дата. */
.page__complaint--card .bar__parent-data > :nth-child(7) > :nth-child(3) { grid-area: area-3; align-self: start; justify-self: center; }   /* Тире. */
.page__complaint--card .bar__parent-data > :nth-child(7) > :nth-child(4) { grid-area: area-4; align-self: start; justify-self: start;  }   /* Дата. */

/*--- Элементы ---*/

/* Бокс. */
.page__complaint--card .bar__parent-data {
	margin-top: 1px;
	margin-bottom: 10px;
	background: var(--back__tab-hover);
	padding-bottom: 1px;
	padding-left: 6px;
	color: var(--back);
}

/* Черта под ссылкой. */
.page__complaint--card .bar__parent-data .link-light {
	border-bottom: dotted 1px;
}

/* Иначе черта не видна (overflow: hidden). */
.page__complaint--card .bar__parent-data > :nth-child(1),
.page__complaint--card .bar__parent-data > :nth-child(3),
.page__complaint--card .bar__parent-data > :nth-child(4),
.page__complaint--card .bar__parent-data > :nth-child(5) {
	padding-bottom: 1px;
}

/*--- Шрифты ---*/

.page__complaint--card .bar__parent-data > :nth-child(2),
.page__complaint--card .bar__parent-data > :nth-child(6),
.page__complaint--card .bar__parent-data > :nth-child(7) > :nth-child(1) {
	font-family: 'thin';
}

.page__complaint--card .bar__parent-data > :nth-child(2),
.page__complaint--card .bar__parent-data > :nth-child(6) {
	font-size: 12px;
}

/*=================/
  Статусная панель
/=================*/

/*-----------/
  Компоновка
/-----------*/

.page__complaint--card .bar__status {
	display: grid;
	grid-template-columns: 6px 200px auto 200px 200px 200px 80px;
	/* column-gap: 8px; */
	grid-template-rows: 44px;
	grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6";
	align-items: center;
}

.page__complaint--card .bar__status > :nth-child(1) { grid-area: area-1; }   /* Тема. */
.page__complaint--card .bar__status > :nth-child(2) { grid-area: area-2; }   /* Статус. */
.page__complaint--card .bar__status > :nth-child(3) { grid-area: area-3; }   /* Кнопка. */
.page__complaint--card .bar__status > :nth-child(4) { grid-area: area-4; }   /* Период спора. */
.page__complaint--card .bar__status > :nth-child(5) { grid-area: area-5; }   /* Период простоя. */
.page__complaint--card .bar__status > :nth-child(6) { grid-area: area-6; }   /* Простой. */

/* Вид панели. */
.page__complaint--card .bar__status {
	margin-bottom: 12px;
	background: var(--text-head);
	color: var(--back);
}

/*--- Тема Спора ---*/

/* Выбор (select): Изначально не выводится. */
.page__complaint--card [name="themes__foreman"],
.page__complaint--card [name="themes__brigadier"] {
	display: none;
}

.page__complaint--card select {
	display: none;
}


/* Выбор (select): Выводим только при создании и только в контексте роли. */
.page__complaint--card.status__complaint--initiate.role__foreman [name="themes__foreman"],
.page__complaint--card.status__complaint--initiate.role__brigadier [name="themes__brigadier"] {
	display: inline-block;
	width: 210px;
	height: 23px;
}

/* Выбранная тема (div): При создании не выводится. */
.page__complaint--card.status__complaint--initiate .theme-complaint {
	display: none;
}

/*--- Статус ---*/

/*
	Названия статусов прописаны во псевдоэлементах
	в общем файле «common_objects.css».
	Здесь — только оформление в контексте страницы.
*/

.page__complaint--card .bar__status > :nth-child(2) {
	justify-self: center;
	border: solid 1px;
	padding: 1px 6px 2px;
	font-family: 'bold';
}

/*
	Фон, статусы:
	«Арбитраж» / «Арбитраж» (в простое) / «Приостановка работ»
*/
.page__complaint--card.status__complaint--arbitrate .bar__status > :nth-child(2),
.page__complaint--card.status__complaint--arbitrate-downtime .bar__status > :nth-child(2),
.page__complaint--card.status__complaint--downtime .bar__status > :nth-child(2) {
	border-color: var(--error-light-2);
	background: hsl(var(--orange-basic), 87%);
}

/*
	Цвет текста, статусы:
	«Арбитраж» / «Арбитраж» (в простое) / «Приостановка работ»
*/
.page__complaint--card.status__complaint--arbitrate .bar__status > :nth-child(2)::before,
.page__complaint--card.status__complaint--arbitrate-downtime .bar__status > :nth-child(2)::before,
.page__complaint--card.status__complaint--downtime .bar__status > :nth-child(2)::before {
	color: var(--error-dark);
}

/*--- Кнопка ---*/

.page__complaint--card .bar__status > :nth-child(3) {
	/* width: 152px; */
	border: solid 1px var(--gray-77);
}

/*--- Период спора ---*/

.page__complaint--card .bar__status > :nth-child(4) {
	display: grid;
	grid-template-columns: 70px 28px 70px;
	grid-template-rows: 20px 22px;
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 area-3 area-4";
}

.page__complaint--card .bar__status > :nth-child(4) > :nth-child(1) { grid-area: area-1; }   /* «Период спора». */
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(2) { grid-area: area-2; }   /* Начало Спора. */
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(3) { grid-area: area-3; }   /* Тире. */
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(4),
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(5) { grid-area: area-4; }   /* Окончание Спора. */

/* «Период спора». */
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(1) {
	align-self: end;
	justify-self: center;
	font-family: 'thin';
	font-size: 12px;
}

/* Начало Спора. */
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(2) {
	justify-self: end;
}

/* Тире. */
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(3) {
	justify-self: center;
}

/*
	Окончание Спора:
		:nth-child(4) Троеточие не выводится в статусах:
			«Закрыт» / «Отклонён» / «Удовлетворён»
		:nth-child(5) Дата изначально не выводится.
*/
.page__complaint--card.status__complaint--end .bar__status > :nth-child(4) > :nth-child(4),
.page__complaint--card.status__complaint--overrule .bar__status > :nth-child(4) > :nth-child(4),
.page__complaint--card.status__complaint--satisfied .bar__status > :nth-child(4) > :nth-child(4),
.page__complaint--card .bar__status > :nth-child(4) > :nth-child(5) {
	display: none;
}

/*
	Окончание Спора: Дата выводится в статусах:
	«Закрыт» / «Отклонён» / «Удовлетворён»
*/
.page__complaint--card.status__complaint--end .bar__status > :nth-child(4) > :nth-child(5),
.page__complaint--card.status__complaint--overrule .bar__status > :nth-child(4) > :nth-child(5),
.page__complaint--card.status__complaint--satisfied .bar__status > :nth-child(4) > :nth-child(5) {
	display: inline-block;
}

/*--- Период простоя ---*/

.page__complaint--card .bar__status > :nth-child(5) {
	display: grid;
	grid-template-columns: 70px 28px 70px;
	grid-template-rows: 20px 22px;
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 area-3 area-4";
}

.page__complaint--card .bar__status > :nth-child(5) > :nth-child(1) { grid-area: area-1; }   /* «Период простоя». */
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(2) { grid-area: area-2; }   /* Начало простоя. */
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(3) { grid-area: area-3; }   /* Тире. */
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(4),
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(5) { grid-area: area-4; }   /* Окончание простоя. */

/* «Период простоя». */
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(1) {
	align-self: end;
	justify-self: center;
	font-family: 'thin';
	font-size: 12px;
}

/* Начало простоя: Дата изначально не выводится. */
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(2) {
	display: none;
}

/*
	Начало простоя: Дата выводится в статусах:
	«Простой по договору» / «Возобновлён»
	«Арбитраж» (в простое) / «Запрос от арбитра» (в простое) / «Дан ответ арбитру» (в простое)
	«Удовлетворён»
*/
.page__complaint--card.status__complaint--downtime .bar__status > :nth-child(5) > :nth-child(2),
.page__complaint--card.status__complaint--restart .bar__status > :nth-child(5) > :nth-child(2),
.page__complaint--card.status__complaint--arbitrate-downtime .bar__status > :nth-child(5) > :nth-child(2),
.page__complaint--card.status__complaint--query-downtime .bar__status > :nth-child(5) > :nth-child(2),
.page__complaint--card.status__complaint--party-respond-downtime .bar__status > :nth-child(5) > :nth-child(2),
.page__complaint--card.status__complaint--satisfied .bar__status > :nth-child(5) > :nth-child(2) {
	display: inline-block;
	justify-self: end;
}

/* Тире. */
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(3) {
	justify-self: center;
}

/*
	Окончание простоя:
		:nth-child(4) Троеточие изначально не выводится.
		:nth-child(5) Дата изначально не выводится.
*/
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(4),
.page__complaint--card .bar__status > :nth-child(5) > :nth-child(5) {
	display: none;
}

/*
	Окончание простоя:
		:nth-child(4) Троеточие выводится в статусах:
			«Простой по договору» / «Возобновлён»
			«Арбитраж» (в простое) / «Запрос от арбитра» (в простое) / «Дан ответ арбитру» (в простое)
		:nth-child(5) Дата выводится в статусе «Удовлетворён».
*/
.page__complaint--card.status__complaint--downtime .bar__status > :nth-child(5) > :nth-child(4),
.page__complaint--card.status__complaint--restart .bar__status > :nth-child(5) > :nth-child(4),
.page__complaint--card.status__complaint--arbitrate-downtime .bar__status > :nth-child(5) > :nth-child(4),
.page__complaint--card.status__complaint--query-downtime .bar__status > :nth-child(5) > :nth-child(4),
.page__complaint--card.status__complaint--party-respond-downtime .bar__status > :nth-child(5) > :nth-child(4),
.page__complaint--card.status__complaint--satisfied .bar__status > :nth-child(5) > :nth-child(5) {
	display: inline-block;
}

/*--- Продолжительность простоя ---*/

.page__complaint--card .bar__status > :nth-child(6) {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 20px 22px;
	grid-template-areas:
		"area-1"
		"area-2";
	justify-items: center;
}

.page__complaint--card .bar__status > :nth-child(6) > :nth-child(1) { grid-area: area-1; }   /* «В простое». */
.page__complaint--card .bar__status > :nth-child(6) > :nth-child(2),
.page__complaint--card .bar__status > :nth-child(6) > :nth-child(3) { grid-area: area-2; }   /* Дни. */

/* «Простой». */
.page__complaint--card .bar__status > :nth-child(6) > :nth-child(1) {
	align-self: end;
	font-family: 'thin';
	font-size: 12px;
}

/*
	Продолжительность:
		:nth-child(2) Тире не выводится в статусах:
			«Простой по договору» / «Возобновлён»
			«Арбитраж» (в простое) / «Запрос от арбитра» (в простое) / «Дан ответ арбитру» (в простое)
			«Удовлетворён»
		:nth-child(3) Дни изначально не выводятся.
*/
.page__complaint--card.status__complaint--downtime .bar__status > :nth-child(6) > :nth-child(2),
.page__complaint--card.status__complaint--restart .bar__status > :nth-child(6) > :nth-child(2),
.page__complaint--card.status__complaint--arbitrate-downtime .bar__status > :nth-child(6) > :nth-child(2),
.page__complaint--card.status__complaint--query-downtime .bar__status > :nth-child(6) > :nth-child(2),
.page__complaint--card.status__complaint--party-respond-downtime .bar__status > :nth-child(6) > :nth-child(2),
.page__complaint--card.status__complaint--satisfied .bar__status > :nth-child(6) > :nth-child(2),
.page__complaint--card .bar__status > :nth-child(6) > :nth-child(3) {
	display: none;
}

/*
	Дни выводятся в статусах:
			«Простой по договору» / «Возобновлён»
			«Арбитраж» (в простое) / «Запрос от арбитра» (в простое) / «Дан ответ арбитру» (в простое)
			«Удовлетворён»
*/
.page__complaint--card.status__complaint--downtime .bar__status > :nth-child(6) > :nth-child(3),
.page__complaint--card.status__complaint--restart .bar__status > :nth-child(6) > :nth-child(3),
.page__complaint--card.status__complaint--arbitrate-downtime .bar__status > :nth-child(6) > :nth-child(3),
.page__complaint--card.status__complaint--query-downtime .bar__status > :nth-child(6) > :nth-child(3),
.page__complaint--card.status__complaint--party-respond-downtime .bar__status > :nth-child(6) > :nth-child(3),
.page__complaint--card.status__complaint--satisfied .bar__status > :nth-child(6) > :nth-child(3) {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

/* Дни. */
.page__complaint--card .bar__status > :nth-child(6) > :nth-child(3) > :nth-child(1) {
	height: 18px;
	background: hsl(var(--orange-basic), 87%);
	padding: 0 4px;
	font-family: 'bold';
	color: var(--error-dark);
}

/* «дн.» */
.page__complaint--card .bar__status > :nth-child(6) > :nth-child(3) > :nth-child(2) {
	margin-left: 4px;
	font-family: 'thin';
}


/*-----------------/
  Заголовки сторон
/-----------------*/

/* При прокрутке прижимаются к верхней панели, но не прокручиваются. */

/*--- Компоновка ---*/

.page__complaint--card .complaint__parties {
  display: grid;
  grid-template-columns: 610px auto 610px;
  /* grid-template-rows: auto; */
  grid-template-areas:
    "child-1 .       child-2"
    "child-3 child-3 child-3"
    "child-4 child-4 child-4";
}

.page__complaint--card .complaint__parties > :nth-child(1) {
  grid-area: child-1;
} /* Инициатор. */
.page__complaint--card .complaint__parties > :nth-child(2) {
  grid-area: child-2;
} /* Ответчик. */
.page__complaint--card .complaint__parties > :nth-child(3) {
  grid-area: child-3;
} /* Секция файлов. */
.page__complaint--card .complaint__parties > :nth-child(4) {
  grid-area: child-4;
} /* Реплики. */

/*--- Позиционарование ---*/

.page__complaint--card .complaint__parties {
  position: sticky;
  z-index: 1;
  top: 76px;
  padding-top: 2px;
  padding-bottom: 8px;
  background: var(--back);
}

/*--- Заголовки сторон ---*/

.page__complaint--card .complaint__parties > :nth-child(1),
.page__complaint--card .complaint__parties > :nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  padding-bottom: 2px;
}

/* Инициатор (цвет). */
.page__complaint--card .complaint__parties > :nth-child(1) {
  background: hsl(var(--green), 26%, 75%);
}

/* Ответчик (цвет). */
.page__complaint--card .complaint__parties > :nth-child(2) {
  background: hsl(var(--orange), 38%, 79%);
}

/* Надписи. */
.page__complaint--card .complaint__parties > :nth-child(1) > :nth-child(1),
.page__complaint--card .complaint__parties > :nth-child(2) > :nth-child(1) {
  margin-right: 6px;
}

/*-------------/
  Файлы сторон
/-------------*/

/* Раскрываемая секция. */

/*--- Компоновка ---*/

.page__complaint--card .complaint__files {
  display: grid;
  grid-template-columns: 610px auto 610px;
  grid-template-rows: 34px auto;
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 .       child-3";
}

.page__complaint--card .complaint__files > :nth-child(1) {
  grid-area: child-1;
} /* «Файлы сторон». */
.page__complaint--card .complaint__files > :nth-child(2) {
  grid-area: child-2;
} /* Файлы Инициатора. */
.page__complaint--card .complaint__files > :nth-child(3) {
  grid-area: child-3;
} /* Файлы Ответчика. */

/*--- Блок файла ---*/

.page__complaint--card .complaint__files > [class*="section__files--"] > div {
  display: grid;
  grid-template-columns: 12px auto;
  grid-template-rows: 20px auto;
  grid-template-areas:
    "child-1 child-2"
    ".       child-3";
}

.page__complaint--card
  .complaint__files
  > [class*="section__files--"]
  > div
  > :nth-child(1) {
  grid-area: child-1;
} /* Маркер. */
.page__complaint--card
  .complaint__files
  > [class*="section__files--"]
  > div
  > :nth-child(2) {
  grid-area: child-2;
} /* Ссылка. */
.page__complaint--card
  .complaint__files
  > [class*="section__files--"]
  > div
  > :nth-child(3) {
  grid-area: child-3;
} /* Комментарий. */

/*--- Секция ---*/

.page__complaint--card .complaint__files {
  border-bottom: solid 1px var(--gray-77);
}

.page__complaint--card .complaint__files .section__header {
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  position: relative; /* Для позиционирования ссылки. */
}

/*--- Ссылка на бокс нового сообщения ---*/

.page__complaint--card .complaint__files .section__header > a {
  position: absolute;
  left: 0;
  border: solid 1px var(--button-back-active);
  border-radius: 12px;
  font-family: "thin";
  padding: 0 12px;
  transition: all 0.2s ease-in-out;
}

.page__complaint--card .complaint__files .section__header > a:hover {
  border-color: var(--button-back-active);
  background: var(--button-back-normal);
  color: var(--button-text-normal);
  text-shadow: var(--button-text-normal-shade);
}

/*--- Файлы сторон: Секции сторон ---*/

/* Изначально свёрнуты. */
.page__complaint--card .complaint__files > [class*="section__files--"] {
  display: none;
}

/* При раскрытии. */
.page__complaint--card .complaint__files.open > [class*="section__files--"] {
  display: block;
  margin-bottom: 6px;
  border: solid 1px var(--gray-77);
  background: var(--back-main);
}

/*--- Файлы сторон: Блок файла ---*/

.page__complaint--card .complaint__files > [class*="section__files--"] > div {
  margin: 6px 6px 6px 16px;
}

/* Ссылка. */
.page__complaint--card .complaint__files > [class*="section__files--"] a,
[class*="replica__"] a {
  border-bottom: dotted 1px;
}

/* Комментарий. */
.page__complaint--card
  .complaint__files
  > [class*="section__files--"]
  > div
  > :nth-child(3) {
  font-size: 12px;
}

/*==============/
  Дерево Реплик
/==============*/

/* При создании не выводятся. */
.page__complaint--card.status__complaint--initiate .complaint__replicas {
	display: none;
}

/*--- Позиционирование ---*/

.page__complaint--card .complaint__replicas {
	display: flex;
	flex-direction: column;
}

/* Бокс реплики. */
.page__complaint--card .complaint__replicas > [class^="replica__"] {
	position: relative;    /* Для выравнивания дат (absolute). */
	margin-bottom: 12px;
	width: 624px;
	border-style: solid;
	padding-top: 8px;
	font-size: 12px;
}

/* Дата. */
.page__complaint--card .complaint__replicas > [class^="replica__"] > :nth-child(1) {
	position: absolute;
	display: flex;
	flex-direction: column;
	white-space: nowrap;
}

/* Время. */
.page__complaint--card .complaint__replicas > [class^="replica__"] > :nth-child(1) > :nth-child(2) {
	font-family: 'thin';
}

/* Текст сообщения. */
.page__complaint--card .replica__initiator > :nth-child(2),
.page__complaint--card .replica__responder > :nth-child(2),
.page__complaint--card [class^="replica__arbitrator--"] > :nth-child(3) {
	width: 100%;
	line-height: 14px;
	white-space: pre-wrap;
}

/*--------------/
  Реплики Спора
/--------------*/

/*--- Компоновка ---*/

.page__complaint--card .complaint__replicas > [class^="replica__"] {
	display: grid;
	grid-template-columns: 12px auto 12px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 ."
		". area-2 .";
}

/*
	:nth-child(1) — Дата — вынесена за блок реплики.
	:nth-child(2) — Сообщение / Заголовок (Арбитр).
	:nth-child(3) — Файлы / Сообщение (Арбитр).
*/
.page__complaint--card .complaint__replicas > [class^="replica__"] > :nth-child(2) { grid-area: area-1; }
.page__complaint--card .complaint__replicas > [class^="replica__"] > :nth-child(3) { grid-area: area-2; }

/*--- Позиционирование: Инициатор ---*/

.page__complaint--card .complaint__replicas .replica__initiator {
	align-self: flex-start;
	border-color: hsl(var(--green), 26%, 75%);
	border-width: 1px 8px 1px 1px;
	background: hsl(var(--green), 26%, 93%);
}

/* Дата. */
.page__complaint--card .complaint__replicas .replica__initiator > :nth-child(1) {
	top: -3px;
	right: -86px;
	align-items: flex-start;
	color: hsl(var(--green), 26%, 55%);
}

.page__complaint--card .complaint__replicas > .replica__initiator > :nth-child(n+2) {
	margin-bottom: 8px;
}

/*--- Позиционирование: Ответчик ---*/

.page__complaint--card .complaint__replicas .replica__responder {
	align-self: flex-end;
	border-color: hsl(var(--orange), 38%, 79%);
	border-width: 1px 1px 1px 8px;
	background: hsl(var(--orange), 38%, 93%);
}

/* Дата. */
.page__complaint--card .complaint__replicas .replica__responder > :nth-child(1) {
	top: -3px;
	left: -86px;
	align-items: flex-end;
	border-top-color: hsl(var(--orange), 38%, 79%);
	color: hsl(var(--orange), 38%, 59%);
}

.page__complaint--card .complaint__replicas > .replica__responder > :nth-child(n+2) {
	margin-bottom: 8px;
}

/*--- Позиционирование: Арбитр ---*/

.page__complaint--card .complaint__replicas [class^="replica__arbitrator--"] {
	align-self: center;
	border-right-width: 8px;
	border-left-width: 8px;
	padding-top: 4px;
}

/* Обрамление при запросе (Запрос от арбитра). */
.page__complaint--card .complaint__replicas .replica__arbitrator--to-initiator,
.page__complaint--card .complaint__replicas .replica__arbitrator--to-responder {
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-color: hsl(var(--blue), 38%, 73%);
	background: hsl(var(--blue), 38%, 95%);
}

/* Обрамление при решении (Арбитраж). */
.page__complaint--card .complaint__replicas .replica__arbitrator--arbitrate {
	border-style: double;
	border-top-width: 2px;
	border-bottom-width: 2px;
	border-color: hsl(var(--blue), 38%, 53%);
	background: hsl(var(--blue), 58%, 93%);
}

/* Дата. */
.page__complaint--card .complaint__replicas [class^="replica__arbitrator--"] > :nth-child(1) {
	top: -3px;
	left: -86px;
	align-items: flex-end;
	border-top-color: hsl(var(--blue), 38%, 73%);
	color: hsl(var(--blue), 38%, 53%);
}

.page__complaint--card .complaint__replicas [class^="replica__arbitrator--"] > :nth-child(2) {
	margin-bottom: 12px;
	font-family: 'bold-italic';
}
.page__complaint--card .complaint__replicas [class^="replica__arbitrator--"] > :nth-child(3) {
	margin-bottom: 8px;
}

/*--- Заголовки Арбитража ---*/

/* Заголовок. */
.page__complaint--card [class^="replica__arbitrator--"] > :nth-child(2) {
	text-align: center;
}
.page__complaint--card [class^="replica__arbitrator--"] > :nth-child(2) > span::before {
	border-bottom: solid 1px;
	padding: 0 10px 4px;
}

/* Арбитраж. */
.page__complaint--card .replica__arbitrator--arbitrate > :nth-child(2) > span::before {
	content: "Арбитраж";
	border-color: hsl(var(--blue), 38%, 73%);
	color: hsl(var(--blue), 78%, 39%);
}

/* К Инициатору. */
.page__complaint--card .replica__arbitrator--to-initiator > :nth-child(2) > span::before {
	content: "Вопрос к Инициатору";
	border-color: hsl(var(--green), 26%, 75%);
	color: hsl(var(--green), 26%, 55%);
}

/* К Ответчику. */
.page__complaint--card .replica__arbitrator--to-responder > :nth-child(2) > span::before {
	content: "Вопрос к Ответчику";
	border-color: hsl(var(--orange), 38%, 79%);
	color: hsl(var(--orange), 38%, 59%);
}

/*--- Тексты автоуведомлений: Арбитр ---*/

.page__complaint--card [class^="replica__arbitrator--"] > :nth-child(3) {
	text-align: center;
}

.page__complaint--card .replica__arbitrator--arbitrate > .text__complaint--overrule::after {
	content: "\000A" "Претензия Инициатора отклонена." "\000A" "Спор закрыт.";
	white-space: pre;   /* Для переноса строки. */
}

.page__complaint--card .replica__arbitrator--arbitrate > .text__complaint--satisfied::after {
	content: "\000A" "Претензия Инициатора удовлетворена." "\000A" "Работа возобновлена." "\000A" "Спор закрыт.";
	white-space: pre;   /* Для переноса строки. */
}

.page__complaint--card .replica__arbitrator--arbitrate > .text__complaint--downtime:nth-child(3)::after {
	content: "\000A" "Включён учёт простоя в работе.";
}

/*--- Тексты автоуведомлений: Инициатор ---*/

.page__complaint--card .replica__initiator > .text__complaint--end::after {
	content: "Вопрос решён." "\000A" "Спор закрыт.";
	white-space: pre;
}

.page__complaint--card .replica__initiator > .text__complaint--satisfied::after {
	content: "Работа возобновлена." "\000A" "Спор закрыт.";
	white-space: pre;
}

/*--- Имена файлов в репликах Инициатора/Ответчика ---*/

.page__complaint--card .complaint__replicas > .replica__initiator > :nth-child(3),
.page__complaint--card .complaint__replicas > .replica__responder > :nth-child(3) {
	display: flex;
	flex-direction: column;
	font-family: 'thin';
}

/* Файлы. */
.page__complaint--card .replica__initiator > :nth-child(3) > :nth-child(n),
.page__complaint--card .replica__responder > :nth-child(3) > :nth-child(n) {
	margin-top: -4px;
}

/* Маркер строки файла. */
.page__complaint--card .replica__initiator > :nth-child(3) > :nth-child(n)::before,
.page__complaint--card .replica__responder > :nth-child(3) > :nth-child(n)::before {
	padding-right: 6px;
}



/*=========================/
  Сектор управления Спором
/=========================*/

/*---------------------------/
  Основное содержание (main)
/---------------------------*/

/*
	При выводе сектора управления он перекрывает
	нижние реплики в дереве реплик.
	Увеличиваем нижнее поле <main>,
	чтобы увеличить диапазон прокрутки.
	Класс «manage__open» добавляется/удаляется скриптом.
*/

.page__complaint--card main.manage__open {
	padding-bottom: 300px;
}

/*------------------/
  Контейнер сектора
/------------------*/

/* Сектор изначально не выводится. */
.page__complaint--card #box__manage-complaint {
	display: none;
}

/*
	Выводим при создании Спора.
	Далее выводим при наличии класса «open».
	Класс добавляется/удаляется скриптом.
	Высота (min-height) подстраиваивается под высоту
	функционального блока, выводимого на текущий момент.
*/
.page__complaint--card.status__complaint--initiate #box__manage-complaint,
.page__complaint--card #box__manage-complaint.open {
	display: block;
	position: fixed;
	right: auto;
	bottom: 44px;
	left: auto;
	width: 1240px;
	min-height: 52px;
	border-bottom: solid 4px var(--gray-57);
	box-shadow: 1px 1px 12px var(--gray-47), -1px -1px 12px var(--gray-47);
	background: var(--back-main);
}

/*------------------------------/
  Добавление сообщения и файлов
/------------------------------*/

/* Блок изначально не выводится. */
.page__complaint--card .manage-complaint__message {
	display: none;
	margin-top: 12px;
	margin-bottom: 8px;
}

/*
	Вывод блока определяется сочетанием: статус Спора — сторона Спора:
		(создание)            — (не имеет значения)
		«Открыт»              — Ответчик
		«Запрос от арбитра»   — Инициатор/Ответчик   (до протоя)
		«Запрос от арбитра»   — Инициатор/Ответчик   (в протое)
		«Простой по договору» — Ответчик
*/
.page__complaint--card.status__complaint--initiate .manage-complaint__message,
.page__complaint--card.status__complaint--start.complaint__party--responder .manage-complaint__message,
.page__complaint--card.status__complaint--query.complaint__party--initiator .manage-complaint__message,
.page__complaint--card.status__complaint--query.complaint__party--responder .manage-complaint__message,
.page__complaint--card.status__complaint--query-downtime.complaint__party--initiator .manage-complaint__message,
.page__complaint--card.status__complaint--query-downtime.complaint__party--responder .manage-complaint__message,
.page__complaint--card.status__complaint--downtime.complaint__party--responder .manage-complaint__message {
	display: grid;
}

/*--- Компоновка ---*/

.page__complaint--card .manage-complaint__message {
	/* display: grid;   определено выше */
	grid-template-columns: 12px 592px auto 592px 12px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 . area-2 .";
}

.page__complaint--card .manage-complaint__message > :nth-child(1) { grid-area: area-1; }   /* Левая половина. */
.page__complaint--card .manage-complaint__message > :nth-child(2) { grid-area: area-2; }   /* Правая половина. */

/*--- Левая половина ---*/

/* Распределение областей. */
.page__complaint--card .manage-complaint__message > :nth-child(1) {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: space-between;
	justify-content: space-between;
}

/* Поле ввода сообщения. */
.page__complaint--card .manage-complaint__message > :nth-child(1) textarea {
	margin-bottom: 12px;
	width: 100%;
	min-height: 62px;
	resize: vertical;

	border: solid 1px var(--gray-87);
	padding: 2px;
	background:  var(--gray-93);
	font-size: 12px;
	line-height: 14px;
	cursor: pointer;
}
.page__complaint--card .manage-complaint__message > :nth-child(1) textarea:focus {
	background: var(--gray-light);
	cursor: text;
}

/* Кнопка «Добавить…» */
.page__complaint--card .manage-complaint__message > :nth-child(1) > :nth-child(2) {
	width: 374px;
}
/* Кнопка «Добавить…» при создании Спора. */
.page__complaint--card.status__complaint--initiate .manage-complaint__message > :nth-child(1) > :nth-child(2) {
	width: 592px;
}

.page__complaint--card .manage-complaint__message.manage-complaint__message--sending > :nth-child(1) > :nth-child(2)::after{
	content: ": Отправка";
}

.page__complaint--card .manage-complaint__message.manage-complaint__message--senderror > :nth-child(1) > :nth-child(2)::after{
	content: ": Ошибка";
}

/* Кнопка «Отмена». */
.page__complaint--card .manage-complaint__message > :nth-child(1) > :nth-child(3) {
	width: 172px;
}
/* Кнопка «Отмена» при создании Спора не выводится. */
.page__complaint--card.status__complaint--initiate .manage-complaint__message > :nth-child(1) > :nth-child(3) {
	display: none;
}

/*--- Правая половина ---*/

.page__complaint--card .manage-complaint__message > :nth-child(2) {
	font-size: 12px;
}

/* Поле комментария. */
.page__complaint--card .manage-complaint__message [type="text"] {
	width: auto;
	height: 18px;
}

/*--- Добавление файла ---*/

.page__complaint--card .manage-complaint__message > :nth-child(2) > form {
	display: grid;
	grid-template-columns: 116px auto;
	grid-template-rows: 22px 22px 22px;
	grid-template-areas:
		"area-1 area-1"
		"area-2 area-3"
		"area-4 area-4";
}

.page__complaint--card .manage-complaint__message > :nth-child(2) > form > :nth-child(1) { grid-area: area-1; }   /* Добавление файла. */
.page__complaint--card .manage-complaint__message > :nth-child(2) > form > :nth-child(2) { grid-area: area-2; justify-self: center; }   /* «Комментарий». */
.page__complaint--card .manage-complaint__message > :nth-child(2) > form > :nth-child(3) { grid-area: area-3; }   /* Комментарий. */
.page__complaint--card .manage-complaint__message > :nth-child(2) > form > :nth-child(4) { grid-area: area-4; }   /* Кнопка. */

/* Кнопка добавления файла. */
.page__complaint--card .manage-complaint__message > :nth-child(2) form button {
	width: 108px;
}

/*--- Заголовок выбранных файлов ---*/

.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(2) {
	margin-top: 8px;
	margin-bottom: 6px;
	background: var(--gray-87);
	padding-top: 2px;
	padding-bottom: 2px;
	text-align: center;
}

/*--- Строка выбранного файла ---*/

.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(n+3) {
	display: grid;
	grid-template-columns: 12px 14px 460px 18px auto;
	grid-template-rows: 20px auto;
	grid-template-areas:
		". area-1 area-2 . area-4"
		". .       area-3 . area-4";
}

.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(n+3) > :nth-child(1) { grid-area: area-1; }   /* Маркер. */
.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(n+3) > :nth-child(2) { grid-area: area-2; }   /* Файл. */
.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(n+3) > :nth-child(3) { grid-area: area-3; }   /* Комментарий. */
.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(n+3) > :nth-child(4) { grid-area: area-4; }   /* Кнопка. */

/* Строки выбранных файлов. */
.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(n+3) {
	margin-bottom: 8px;
}

/* Кнопка удаления выбранного файла. */
.page__complaint--card .manage-complaint__message > :nth-child(2) > :nth-child(n+3) button {
	align-self: center;
	width: 76px;
}

/*-------------------/
  Решение Инициатора
/-------------------*/

/* Блок изначально не выводится. */
.page__complaint--card .manage-complaint__initiator {
	display: none;
}

/*
	Вывод блока только для Инициатора для статусов:
	«Дан ответ» / «Простой по договору» / «Возобновлён»
*/
.page__complaint--card.status__complaint--respond.complaint__party--initiator .manage-complaint__initiator,
.page__complaint--card.status__complaint--downtime.complaint__party--initiator .manage-complaint__initiator,
.page__complaint--card.status__complaint--restart.complaint__party--initiator .manage-complaint__initiator {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	height: 48px;
}

/* Все элементы, кроме кнопки «Отмена», изначально скрыты. */
.page__complaint--card .manage-complaint__initiator > :not([name="control__manage-close"]) {
	display: none;
}

/*--- Кнопки ---*/

/* Все кнопки. */
.page__complaint--card .manage-complaint__initiator button {
	width: 172px;
}

/*
	Выводим кнопки (возврщаем исходный «flex»):
		статус «Дан ответ»:           «Закрыть спор»   / «Арбитраж»
		статус «Возобновлён»:         «Завершить спор» / «Арбитраж»
		статус «Простой по договору»: «Завершить спор»
*/
.page__complaint--card.status__complaint--respond .manage-complaint__initiator > [name="control__complaint--end"],
.page__complaint--card.status__complaint--respond .manage-complaint__initiator > [name="control__complaint--arbitrate"],
.page__complaint--card.status__complaint--restart .manage-complaint__initiator > [name="control__complaint--arbitrate"],
.page__complaint--card.status__complaint--downtime .manage-complaint__initiator > [name="control__complaint--satisfied"],
.page__complaint--card.status__complaint--restart .manage-complaint__initiator > [name="control__complaint--satisfied"] {
	display: flex;
}

/*--- Блок даты окончания простоя ---*/

/* Выводим в статусах «Простой по договору» и «Возобновлён». */
.page__complaint--card.status__complaint--downtime .manage-complaint__initiator > .block__downtime--date-end,
.page__complaint--card.status__complaint--restart .manage-complaint__initiator > .block__downtime--date-end {
	display: block;
}

/* Надпись. */
.page__complaint--card .manage-complaint__initiator label {
	font-family: 'thin';
	margin-right: 6px;
}

/* Поле календаря. */
.page__complaint--card .manage-complaint__initiator input {
	width: 114px;
}

/*----------------/
  Решение Арбитра
/----------------*/

/*--- Компоновка ---*/

/* Блок изначально не выводится. */
.page__complaint--card .manage-complaint__arbitrator {
	display: none;
}

/*
	Вывод блока только для Арбитра в статусах:
		«Арбитраж»          / «Арбитраж» (в простое)
		«Запрос от арбитра» / «Запрос от арбитра» (в простое)
		«Дан ответ арбитру» / «Дан ответ арбитру» (в простое)
*/
.page__complaint--card.status__complaint--arbitrate.complaint__party--arbitrator .manage-complaint__arbitrator,
.page__complaint--card.status__complaint--arbitrate-downtime.complaint__party--arbitrator .manage-complaint__arbitrator,

.page__complaint--card.status__complaint--query.complaint__party--arbitrator .manage-complaint__arbitrator,
.page__complaint--card.status__complaint--query-downtime.complaint__party--arbitrator .manage-complaint__arbitrator,

.page__complaint--card.status__complaint--party-respond.complaint__party--arbitrator .manage-complaint__arbitrator,
.page__complaint--card.status__complaint--party-respond-downtime.complaint__party--arbitrator .manage-complaint__arbitrator {
	display: grid;
	grid-template-columns: 620px 620px;   /* =1240px */
	grid-template-rows: 34px max-content 34px;
	grid-template-areas:
		"area-2 area-4"
		"area-5 area-5"
		"area-6 area-6";
}

/* :nth-child(1) — радио-кнопка для вкладки 1, скрыта. */
/* :nth-child(2) — Ярлык вкладки 1. */
.page__complaint--card .manage-complaint__arbitrator > :nth-child(2)  { grid-area: area-2; }

/* :nth-child(3) — радио-кнопка для вкладки 2, скрыта. */
/* :nth-child(4) — Ярлык вкладки 2. */
.page__complaint--card .manage-complaint__arbitrator > :nth-child(4)  { grid-area: area-4; }

/* :nth-child 5 и 6 — содержание текущей вкладки. */
.page__complaint--card .manage-complaint__arbitrator > :nth-child(5),
.page__complaint--card .manage-complaint__arbitrator > :nth-child(6)  { grid-area: area-5; }

/* :nth-child(7) — Кнопка «Отмена». */
.page__complaint--card .manage-complaint__arbitrator > :nth-child(7)  { grid-area: area-6; }

/*--- Радиокнопки ---*/

/* Индикаторы скрыты (переключение по надписям). */
.page__complaint--card .manage-complaint__arbitrator > input[type="radio"] {
	display: none;
}

/*--- Ярлыки вкладок ---*/

/* Общий вид. */
.page__complaint--card .manage-complaint__arbitrator > label {
	display: block;
	position: relative;   /* Для выравнивания вложенного <span>. */
	width: 620px;
	height: 34px;
	border: solid 2px;
	text-align: center;

	transition: all 0.3s ease-in-out;
}

/*	<span> фоном перекрывает границу текущего содержания. */
.page__complaint--card .manage-complaint__arbitrator > label > span {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	padding-top: 6px;
}

/* Неактивный ярлык. */
.page__complaint--card .manage-complaint__arbitrator :not(:checked) + label {
	background: var(--text-head);
	border-color: var(--back);
	font-family: 'thin';
	color: var(--back);
}

/* Неактивный недоступный ярлык. */
.page__complaint--card .manage-complaint__arbitrator :not(:checked):disabled + label,
.page__complaint--card .manage-complaint__arbitrator :not(:checked):disabled + label:hover {
	background: var(--gray-67);
	color: var(--gray-87);
	cursor: not-allowed;
}

/* Неактивный ярлык при наведении. */
.page__complaint--card .manage-complaint__arbitrator :not(:checked) + label:hover {
	background: var(--back__tab-hover);
	cursor: pointer;
}

/* Текущий ярлык. */
.page__complaint--card .manage-complaint__arbitrator > :checked + label {
	border-color: var(--blue__48-53);
	font-family: 'bold';
	color: var(--text-head);
	cursor: default;
}
.page__complaint--card .manage-complaint__arbitrator > #tab__query:checked + label {
	border-left: none;
	padding-left: 2px;   /* Компенсация толщины убранной границы. */
}
.page__complaint--card .manage-complaint__arbitrator > #tab__ruling:checked + label {
	border-right: none;
	padding-right: 2px;   /* Компенсация толщины убранной границы. */
}

/*	<span> фоном перекрывает границу текущего содержания. */
.page__complaint--card .manage-complaint__arbitrator > :checked + label span {
	background: var(--back-main);
	height: 34px;
}

/*--- Переключение вкладок ---*/

/* Блоки содержания изначально скрыты. */
.page__complaint--card .manage-complaint__arbitrator > [id^="content__"] {
	display: none;
}

/* Привязка содержания к выбранной вкладке. */
.page__complaint--card #tab__query:checked ~ #content__query,
.page__complaint--card #tab__ruling:checked ~ #content__ruling {
	display: grid;
	grid-template-columns: 320px 600px 320px;   /* =1240px ширина сектора. */
	justify-items: center;
	align-items: start;

	border-top: solid 2px var(--blue__48-53);
	padding-top: 12px;
}

/* Кнопка «Отмена». */
.page__complaint--card .manage-complaint__arbitrator > [name="control__manage-close"] {
	grid-area: area-6;
	align-self: center;
	justify-self: center;
}

/* Кнопки (все): размер. */
.page__complaint--card .manage-complaint__arbitrator button {
	width: 172px;
}

/*-------------------/
  Содержание вкладок
/-------------------*/

/*--- Содержание вкладки «Запрос участникам» ---*/

/* Компоновка. */
.page__complaint--card #content__query {
	grid-template-areas:
		"area-2 area-1 area-3";
}

/* Поле ввода текста. */
.page__complaint--card #content__query > textarea {
	grid-area: area-1;

	margin-bottom: 12px;
	width: 100%;
	min-height: 62px;
	resize: vertical;
	border: solid 1px var(--gray-87);
	padding: 2px;
	background: var(--gray-93);
	font-size: 12px;
	line-height: 14px;
	cursor: pointer;
}
.page__complaint--card #content__query > textarea:focus {
	background: var(--gray-light);
	cursor: text;
}

/* Кнопка «Запрос Инициатору». */
.page__complaint--card #content__query > [name="control__query-to-initiator"] {
	grid-area: area-2;
}

/* Кнопка «Запрос Ответчику». */
.page__complaint--card #content__query > [name="control__query-to-responder"] {
	grid-area: area-3;
}

/*--- Содержание вкладки «Решение Арбитра» ---*/

/* Компоновка. */
.page__complaint--card #content__ruling {
	grid-template-areas:
		"area-2 area-1 area-4"
		"area-3 area-1 area-5";
	grid-template-rows: 38px auto;
}

/* area-1: Поле ввода текста. */
.page__complaint--card #content__ruling > textarea {
	grid-area: area-1;

	margin-bottom: 12px;
	width: 100%;
	min-height: 62px;
	resize: vertical;
	border: solid 1px var(--gray-87);
	padding: 2px;
	background: var(--gray-93);
	font-size: 12px;
	line-height: 14px;
	cursor: pointer;
}
.page__complaint--card #content__ruling > textarea:focus {
	background: var(--gray-light);
	cursor: text;
}

/*
	Кнопки и поля в контексте состояния: до простоя / в простое
	Статусы: «Арбитраж» / «Запрос от арбитра» / «Дан ответ арбитру»
	Изначально кнопки и блоки не выводятся.
*/
.page__complaint--card #content__ruling > [name="control__complaint--overrule"],
.page__complaint--card #content__ruling > [name="control__complaint--satisfied"],
.page__complaint--card #content__ruling > .block__downtime--end,
.page__complaint--card #content__ruling > .block__downtime--start {
	display: none;
}

/*
	area-2 до простоя: кнопка «Спор отклонён».
	area-2 в простое:  кнопка «Спор удовлетворён».
*/
.page__complaint--card.status__complaint--arbitrate #content__ruling > [name="control__complaint--overrule"],
.page__complaint--card.status__complaint--query #content__ruling > [name="control__complaint--overrule"],
.page__complaint--card.status__complaint--party-respond #content__ruling > [name="control__complaint--overrule"],

.page__complaint--card.status__complaint--arbitrate-downtime #content__ruling > [name="control__complaint--satisfied"],
.page__complaint--card.status__complaint--query-downtime #content__ruling > [name="control__complaint--satisfied"],
.page__complaint--card.status__complaint--party-respond-downtime #content__ruling > [name="control__complaint--satisfied"] {
	grid-area: area-2;

	display: flex;   /* Изначально кнопки не выводятся, возвращаем значение. */
}

/* area-3 в простое: Блок даты окончания простоя. */
.page__complaint--card.status__complaint--arbitrate-downtime #content__ruling > .block__downtime--end,
.page__complaint--card.status__complaint--query-downtime #content__ruling > .block__downtime--end,
.page__complaint--card.status__complaint--party-respond-downtime #content__ruling > .block__downtime--end {
	grid-area: area-3;

	display: flex;   /* Изначально блок не выводится, выводим. */
}

/* area-4 до простоя и в простое: кнопка «Простой по договору». */
.page__complaint--card.status__complaint--arbitrate #content__ruling > [name="control__complaint--downtime"] {
	grid-area: area-4;
}

/* area-5 до простоя: Блок даты начала простоя. */
.page__complaint--card.status__complaint--arbitrate:not(.status__complaint--arbitrate-downtime) #content__ruling > .block__downtime--start,
.page__complaint--card.status__complaint--query #content__ruling > .block__downtime--start,
.page__complaint--card.status__complaint--party-respond #content__ruling > .block__downtime--start {
	grid-area: area-5;

	display: flex;   /* Изначально блок не выводится, выводим. */
}

/*--- Блок даты начала простоя ---*/

.page__complaint--card #content__ruling > .block__downtime--start,
.page__complaint--card #content__ruling > .block__downtime--end {
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
}

/* Надпись. */
.page__complaint--card #content__ruling label {
	font-family: 'thin';
	margin-right: 6px;
}

/* Поле календаря. */
.page__complaint--card #content__ruling [type="date"] {
	width: 114px;
	height: 24px;
}

.control__manage-files {
	display: flex;
	margin-bottom: 2px;
}

.control__manage-files :nth-child(2){
	flex: 1 1 auto;
	text-align: left;
} 

.control__manage-files :nth-child(3):nth-child(4){
	flex: 0 0 auto;
} 